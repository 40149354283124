import React, { useState, useEffect } from "react";
import { onError } from "../lib/errorLib";
import { v4 as uuidv4 } from 'uuid';
import { API } from "aws-amplify";
import dayjs from 'dayjs';
import { useForm, Controller } from 'react-hook-form';
import { DevTool } from '@hookform/devtools'
// Generic Modal Utility
import GenericModal from "./GenericModal";
import { Tabs } from 'antd';
import { Divider, Table, Tag, TableProps, Space, Checkbox, CheckboxProps, Row, Col, Spin } from 'antd';
import { Button, Modal, Form, Input, notification, Select, DatePicker, TimePicker, Switch, Card } from 'antd';
import { DoctorCard_CalendarInfo, ClinicInfoCard_DoctorCalendar } from "../utilityComponents/profileComponents/profileComponents.js";
import { doctorHourTimes } from '../utilityComponents/profileComponents/doctorHourTimes';

import {
    CheckCircleTwoTone,
    CloseCircleTwoTone,
    DownOutlined 
} from '@ant-design/icons';

function CreateOrEditShiftModal(props) {

    const { 
        isMobile,
        currentPage,
        handleCancel,
        userData,
        orgData,
        clinicData,
        onLoginEvent,
        setIsCreateOrEditShiftModalOpen,
        isCreateOrEditShiftModalOpen,
        currentlySelectedClinicCalendar,
        currentlySelectedShiftToEdit,
        selectedCalendarDate,
        shiftsWithInterestedDoctors,
        rePullClinicData,
        // setCurrentlyEdittingInformation
    } = props

    const [shiftId, setShiftId] = useState("");
    const [clinicId, setClinicId] = useState(""); 
    const [clinicName, setClinicName] = useState("Which clinic are you posting for?"); 
    const [shiftType, setShiftType] = useState("");
    const [shiftDate, setShiftDate] = useState("");
    const [shiftState, setShiftState] = useState("Available"); // does this need to be managed by react hook for, if it does not have a cooresponding ui element? maybe.
    const [shiftDoctorHours, setShiftDoctorHours] = useState([]);
    const [shiftDoctorHoursSimpleArray, setShiftDoctorHoursSimpleArray] = useState([]);

    const [shiftDoctorHours_StartTime, setShiftDoctorHours_StartTime] = useState("");
    const [shiftDoctorHours_EndTime, setShiftDoctorHours_EndTime] = useState("");

    const [shiftPay, setShiftPay] = useState("");
    const [shiftNotes, setShiftNotes] = useState(""); 
    const [inviteDoctorsValue, setInviteDoctorsValue] = useState([]);
    const [isRecurring, setIsRecurring] = useState(false);
    const [isGlassesAndContacts, setIsGlassesAndContacts] = useState(true); // routine
    const [isMedical, setIsMedical] = useState(false); // medical
    const [shiftRecurringSelection, setShiftRecurringSelection] = useState("");
    const [isEdittingExistingShift, setIsEdittingExistingShift] = useState(false);
    // const [firstPageHasBeenRendered, setFirstPageHasBeenRendered] = useState(false);
    const [interestedDoctorTableData, setInterestedDoctorTableData] = useState([]); // formatted interestedDoctors data for the table logic
    const [activeTabKey, setActiveTabKey] = useState('1');
    const [doctorListLoading, setDoctorListLoading] = useState(false);
    const [doctorListLoaded, setDoctorListLoaded] = useState(false);

    // Doctor Information Modal
    const[currentlySelectedDoctorInformation , setCurrentlySelectedDoctorInformation] = useState();
    const[isDoctorInformationModalOpen, setIsDoctorInformationModalOpen] = useState(false);

    // Clinic Information Modal
    const[currentlySelectedClinicInformation , setCurrentlySelectedClinicInformation] = useState();
    const [isClinicInformationModalOpen, setIsClinicInformationModalOpen ] = useState(false);

    // Accept Doctor Flow
    const[currentlySelectedDoctorToAcceptForShift , setCurrentlySelectedDoctorToAcceptForShift] = useState();
    const[confirmAcceptDoctorButtonLoading, setConfirmAcceptDoctorButtonLoading] = useState(false);

    // For Generic Modal Utility - Delete Shift
    const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false); // generic modal - used in add clinic process.

    // For Generic Modal Utility - Mark Shift as Scheduled Externally
    const [isMarkShiftAsScheduledExternallyConfirmationModalOpen, setIsMarkShiftAsScheduledExternallyConfirmationModalOpen] = useState(false); // generic modal 

    // For Generic Modal Utility - Vonfirm Accept Doctor
    const [isOpenAcceptDoctorModal, setIsOpenAcceptDoctorModal] = useState(false); // generic modal 

    // Edit accepted Doctor
    const [isEditAcceptedDoctorModalOpen, setIsEditAcceptedDoctorModalOpen] = useState(false);
    const [isConfirmDoctorCancelShiftModalOpen, setIsConfirmDoctorCancelShiftModalOpen] = useState(false);
    

    // setting default values for react-hook-form
    // dynamically populated info from props is passed into the defaultValues below in the useEffect hook.
    const [defaultValues, setDefaultValues] = useState({ 
        clinicName: '', 
        shiftType: 'singleShift',
        shiftDate: null, 
        shiftState: 'Available',
        shiftDoctorHours: [],
        isRecurring: false,
        isGlassesAndContacts: true,
        isMedical: false,
        recurringOptions: '',
        shiftPayment: '',
        shiftNotes: '',
        inviteDoctors: []
    });

    function validateCreateShift() {
        // Check to make sure that not only one of the shiftDoctorHours_StartTime or shiftDoctorHours_EndTime is set.
        // if (shiftDoctorHours_StartTime !== "" && shiftDoctorHours_EndTime === "" || shiftDoctorHours_EndTime !== "" && shiftDoctorHours_StartTime === "") {

        // console.log("shiftDoctorHoursSimpleArray: ", shiftDoctorHoursSimpleArray);
        // console.log("shiftDoctorHours_StartTime: ", shiftDoctorHours_StartTime);
        // console.log("shiftDoctorHours_EndTime: ", shiftDoctorHours_EndTime);
        // console.log("currentlySelectedShiftToEdit: ", currentlySelectedShiftToEdit);

        // // validate for edit needs to be different than validate for create
        if (currentlySelectedShiftToEdit !== "") {
            // console.log("validate for edit shift");
            if (shiftDoctorHoursSimpleArray[0] !== "" || shiftDoctorHoursSimpleArray[1] !== "") {
                return false;
            } else {
                return true;
            }
        } else {
            // console.log("validate for create shift");
            // console.log("shiftDoctorHoursSimpleArray: ", shiftDoctorHoursSimpleArray);
            // if (shiftDoctorHoursSimpleArray[0] !== "" || shiftDoctorHoursSimpleArray[1] !== "") {
            if (shiftDoctorHoursSimpleArray[0] !== undefined && shiftDoctorHoursSimpleArray[1] !== undefined) {
                // console.log("shiftDoctorHoursSimpleArray is not empty");

                return false;
            } else {
                // console.log("shiftDoctorHoursSimpleArray is empty");
                return true;
            }
        }
    }

    // react-hook-form: //
    // The form control enables react-hook-form to start tracking the state of this form.
    // One control object to be used in all fields on the form to join and track that data from a single object.
    // "react hook form is in charge of the form state"
    const { handleSubmit, control, formState: { errors }, reset, setValue } = useForm({ defaultValues });

    // UI Setup
    const [api, contextHolder] = notification.useNotification();
    const { Option } = Select;
    const { TextArea } = Input;

    const createOrUpdateShift = (updateShiftState) => {
        // console.log("create shift..", data);

        let clinic;
        let deconstructedClinicData;
        for(var i=0; i<clinicData.length; i++){
            if(clinicData[i].clinicId == clinicId){

                // clinic.clinicName = clinicData[i].clinicName;
                clinic = clinicData[i];
                // console.log("clinicData to include embedded in shift data... : ", clinic);

                // use destructuring to only take part of this clinicData object.. 
                // we dont want formattedShiftData, nor shiftData, everything else is fine to save.
                const { formattedShiftData, shiftData, ...deconstructedObj } = clinic;
                // console.log("deconstructed ClinicData to pass to shiftData: ", deconstructedObj);
                deconstructedClinicData = deconstructedObj;
            }
        }

        // Shift Data Params for API Call
        const shiftData_GeneralCreateOrUpdate = {
            orgId: userData.orgId,
            clinicId: clinicId, 
            clinicData: deconstructedClinicData,
            orgData: orgData,
            shiftId: getShiftIdForEditOrCreate(), 
            shiftType: shiftType,
            shiftDate: shiftDate,
            shiftState: shiftState, 
            shiftDoctorHours: shiftDoctorHoursSimpleArray,
            isRecurring: isRecurring,
            isGlassesAndContacts: isGlassesAndContacts,
            isMedical: isMedical,
            shiftRecurringSelection: shiftRecurringSelection,
            shiftPay: shiftPay,
            shiftNotes: shiftNotes
        }
        console.log("shiftData_GeneralCreateOrUpdate: ", shiftData_GeneralCreateOrUpdate);


        // Shift Data Params for API Call
        const shiftData_MarkShiftAsScheduledExternally = {
            clinicId: clinicId,
            shiftId: getShiftIdForEditOrCreate(),
            shiftState: "Scheduled",
            acceptedDoctor: {
                doctorId: "scheduled externally",
                doctorName: "scheduled externally",
                timestamp: new Date().toISOString()
            }
        }
        console.log("shiftData_MarkShiftAsScheduledExternally: ", shiftData_MarkShiftAsScheduledExternally);
    
        // additional form validation
        if (shiftDoctorHoursSimpleArray.length > 0 && shiftDate !== ""){
            // Check to see if the user is updating general shift information, or "marking the shift as scheduled externally"
            // if "marking the shift as scheduled externally", then we need to bypass the current shiftState and provide a new one of "Scheduled"

            // check to make sure this is coming through correctly
            console.log("updateShiftState: ", updateShiftState);
            if (updateShiftState === undefined) {
                // General create or update shift logic
                createOrUpdateShift_CallApi(shiftData_GeneralCreateOrUpdate);
            } else {
                // Marking the shift as scheduled externally outside of OD Shift
                createOrUpdateShift_CallApi(shiftData_MarkShiftAsScheduledExternally);
            }

            setIsCreateOrEditShiftModalOpen(false);
        }else{
            // alert("Please enter doctor hours");
        }

    };

    const deleteShiftConfirmation = () => {

        // open generic modal ,are you sure you want to delete this shift?
        // console.log("delete shift confirmation button clicked..");
        setIsDeleteConfirmationModalOpen(true);
    };

    const markShiftScheduledExternallyConfirmation = () => {

        // open generic modal ,are you sure you want to delete this shift?
        // console.log("delete shift confirmation button clicked..");
        // console.log("mark shift scheduled externally.");
        setIsMarkShiftAsScheduledExternallyConfirmationModalOpen(true);
    };

    const deleteShift = () => {
        setIsDeleteConfirmationModalOpen(false);
        setIsCreateOrEditShiftModalOpen(false);
        // open generic modal ,are you sure you want to delete this shift?
        console.log("delete shift button clicked.. now hit api to delete");

        const shiftData = {
            orgId: userData.orgId,
            clinicId: clinicId, 
            shiftId: currentlySelectedShiftToEdit.shiftId
        }
        console.log("shiftData for delete: ", shiftData );

        // API call to delete the shift
        try {
            return API.put("od-api", "/shiftData", {
                body: shiftData,
            }).then((data) => {
            console.log("create or update shift data response: ", data);
            if(data.statusCode === 200){ // check response to see if succesful or not.
                
                openNotification('Deleted Shift Succesfully');
                const parsedBody = JSON.parse(data.body);
                console.log("shift delete response: ", parsedBody);

                // PULL CLINIC DATA AFTER CREATE or UPDATE //
                // pass clinicId to loop and grab correct currently selected calendar clinic data and populate state.
                rePullClinicData(clinicId);
   
            }else{
                openNotification('Shift Deletion Failed');
            }
            });
        } catch (e) {
            onError(e);
            console.log("ERROR: ", e);
        }
    };

    const confirmScheduledExternally = () => {
        setIsMarkShiftAsScheduledExternallyConfirmationModalOpen(false);
        setIsCreateOrEditShiftModalOpen(false);
        // open generic modal ,are you sure you want to mark this shift as scheduled externally?
        console.log("confirm shift scheduled externally button clicked.. now hit api to execute logic");
        console.log("MAKING API CALL - To update a Shift.")

        // pass argument to tell the function to specifcally change the shift status to scheduled...
        createOrUpdateShift("Scheduled");
    };

    // utility to determine the shiftId conditionally whether you are creating or editing a shift
    const getShiftIdForEditOrCreate = () => {
        let shiftIdToSend = ""
        console.log("currentlySelectedShiftToEdit inside getShiftIdForEditOrCreate: ", currentlySelectedShiftToEdit);
        if(currentlySelectedShiftToEdit.shiftId != undefined){
            shiftIdToSend = currentlySelectedShiftToEdit.shiftId
        }else{
            shiftIdToSend = uuidv4();
        }
        console.log("shiftIdToSend: ", shiftIdToSend);
        return shiftIdToSend
    }

    function createOrUpdateShift_CallApi(shiftData) {
        console.log("MAKING API CALL - To Create or Update a Shift.")
        try {
            return API.post("od-api", "/shiftData", {  // createShiftData.js
                body: shiftData,
            }).then((data) => {
            console.log("create or update shift data response: ", data);
            if(data.statusCode === 200){ // check response to see if succesful or not.
                
                if(isEdittingExistingShift){
                    openNotification('Updated Shift Succesfully');
                }else{
                    openNotification('Created Shift Succesfully');
                }
                const parsedBody = JSON.parse(data.body);
                console.log("shift creation or update response: ", parsedBody);

                // PULL CLINIC DATA AFTER CREATE or UPDATE //
                // pass clinicId to loop and grab correct currently selected calendar clinic data and populate state.
                rePullClinicData(clinicId);
                onLoginEvent(true);

                setShiftDoctorHours_StartTime("");
                setShiftDoctorHours_EndTime("");
                setShiftDoctorHoursSimpleArray([]);

                // after update need to clear/reset fields
                // closeModal();
                reset(defaultValues);
   
            }else{
                openNotification('Shift Creation Failed');
            }
            });
        } catch (e) {
            onError(e);
            console.log("ERROR: ", e);
        }
    };

    useEffect(() => {
        // Get the default values for react-hook-form from props

        // REFACTOR: Abstract out this utility..
        const fetchDefaultValues = async () => {
            // console.log("currentlySelectedClinicCalendar: ", currentlySelectedClinicCalendar);

            setClinicId(currentlySelectedClinicCalendar?.clinicId);
            setClinicName(currentlySelectedClinicCalendar?.clinicName);

            // setShiftDoctorHoursSimpleArray(currentlySelectedClinicCalendar?.clinicDoctorHours); // to send to dynamo
            // setShiftDoctorHours([dayjs(currentlySelectedClinicCalendar?.clinicDoctorHours[0], 'HH:mm'), dayjs(currentlySelectedClinicCalendar?.clinicDoctorHours[1], 'HH:mm')]); 

            // console.log("currentlySelectedClinicCalendar?.clinicDoctorHours: ", currentlySelectedClinicCalendar?.clinicDoctorHours);
            // console.log("dayjs(currentlySelectedClinicCalendar?.clinicDoctorHours[0]: ", dayjs(currentlySelectedClinicCalendar?.clinicDoctorHours[0], 'HH:mm'));
            // console.log("dayjs(currentlySelectedClinicCalendar?.clinicDoctorHours[1]: ", dayjs(currentlySelectedClinicCalendar?.clinicDoctorHours[1], 'HH:mm'));

            // // These are not set correctly when the clinic calendar is changed.
            // if (shiftDoctorHoursSimpleArray !== undefined){
            //     console.log("shiftDoctorHoursSimpleArray: ", shiftDoctorHoursSimpleArray);
            //     console.log("shiftDoctorHoursSimpleArray[0]: ", shiftDoctorHoursSimpleArray[0]);
            // }else{
            //     console.log("shiftDoctorHoursSimpleArray !== undefined");
            // }

            // IMPORTANT //
            // Manage the default initial values show in the create or edit shift modal.
            // Need to conditionally populate these differently whether editting a shift or creating a new one.
            // If editting, use shiftDoctorHoursSimpleArray, if creating new, use currentlySelectedClinicCalendar
            // Check if creating a new clinic or editting an existing clinic.
            // console.log("isEdittingExistingShift: ", isEdittingExistingShift);
            if (isEdittingExistingShift && userData.userType === "clinicTeamMember") {
                // console.log("test shiftDoctorHoursSimpleArray: ", shiftDoctorHoursSimpleArray);

                if (currentlySelectedClinicCalendar !== undefined) {

                    // Clinic Defaults
                    setDefaultValues({
                        ...defaultValues,
                        clinicName: currentlySelectedClinicCalendar.clinicName,
                        clinicId: currentlySelectedClinicCalendar.clinicId,
                        shiftDate: dayjs(shiftDate, "YYYY-MM-DD"),
                        isGlassesAndContacts: currentlySelectedClinicCalendar.isGlassesAndContacts,
                        isMedical: currentlySelectedClinicCalendar.isMedical,
                        // shiftDoctorHours: shiftDoctorHoursSimpleArray[0] === undefined ? [] : [dayjs(shiftDoctorHoursSimpleArray[0], 'HH:mm'), dayjs(shiftDoctorHoursSimpleArray[1], 'HH:mm')],
                        shiftDoctorHours: shiftDoctorHoursSimpleArray[0] === undefined ? [] : [shiftDoctorHoursSimpleArray[0], shiftDoctorHoursSimpleArray[1]],
                        // shiftDoctorHours: currentlySelectedClinicCalendar?.clinicDoctorHours === "" ? [] : [dayjs(currentlySelectedClinicCalendar?.clinicDoctorHours[0], 'HH:mm'), dayjs(currentlySelectedClinicCalendar?.clinicDoctorHours[1], 'HH:mm')],
                    });
                    reset({
                        ...defaultValues,
                        clinicName: currentlySelectedClinicCalendar.clinicName,
                        clinicId: currentlySelectedClinicCalendar.clinicId,
                        shiftDate: dayjs(shiftDate, "YYYY-MM-DD"),
                        isGlassesAndContacts: currentlySelectedClinicCalendar.isGlassesAndContacts,
                        isMedical: currentlySelectedClinicCalendar.isMedical,
                        // shiftDoctorHours: shiftDoctorHoursSimpleArray[0] === undefined ? [] : [dayjs(shiftDoctorHoursSimpleArray[0], 'HH:mm'), dayjs(shiftDoctorHoursSimpleArray[1], 'HH:mm')],
                        shiftDoctorHours: shiftDoctorHoursSimpleArray[0] === undefined ? [] : [shiftDoctorHoursSimpleArray[0], shiftDoctorHoursSimpleArray[1]],
                        // shiftDoctorHours: currentlySelectedClinicCalendar?.clinicDoctorHours === "" ? [] : [dayjs(currentlySelectedClinicCalendar?.clinicDoctorHours[0], 'HH:mm'), dayjs(currentlySelectedClinicCalendar?.clinicDoctorHours[1], 'HH:mm')],

                    });
                }

            } else {
                if (currentlySelectedClinicCalendar !== undefined) {
                    // console.log("test currentlySelectedClinicCalendar: ", currentlySelectedClinicCalendar);

                    // Clinic Defaults
                    setDefaultValues({
                        ...defaultValues,
                        clinicName: currentlySelectedClinicCalendar.clinicName,
                        clinicId: currentlySelectedClinicCalendar.clinicId,
                        shiftDate: dayjs(shiftDate, "YYYY-MM-DD"),
                        isGlassesAndContacts: currentlySelectedClinicCalendar.isGlassesAndContacts,
                        isMedical: currentlySelectedClinicCalendar.isMedical,
                        // shiftDoctorHours: shiftDoctorHoursSimpleArray[0] === undefined ? [] : [dayjs(shiftDoctorHoursSimpleArray[0], 'HH:mm'), dayjs(shiftDoctorHoursSimpleArray[1], 'HH:mm')],
                        // shiftDoctorHours: currentlySelectedClinicCalendar?.clinicDoctorHours === "" ? [] : [dayjs(currentlySelectedClinicCalendar?.clinicDoctorHours[0], 'HH:mm'), dayjs(currentlySelectedClinicCalendar?.clinicDoctorHours[1], 'HH:mm')],
                        shiftDoctorHours: currentlySelectedClinicCalendar?.clinicDoctorHours === "" ? [] : [currentlySelectedClinicCalendar?.clinicDoctorHours[0], currentlySelectedClinicCalendar?.clinicDoctorHours[1]],

                    });
                    reset({
                        ...defaultValues,
                        clinicName: currentlySelectedClinicCalendar.clinicName,
                        clinicId: currentlySelectedClinicCalendar.clinicId,
                        shiftDate: dayjs(shiftDate, "YYYY-MM-DD"),
                        isGlassesAndContacts: currentlySelectedClinicCalendar.isGlassesAndContacts,
                        isMedical: currentlySelectedClinicCalendar.isMedical,
                        // shiftDoctorHours: shiftDoctorHoursSimpleArray[0] === undefined ? [] : [dayjs(shiftDoctorHoursSimpleArray[0], 'HH:mm'), dayjs(shiftDoctorHoursSimpleArray[1], 'HH:mm')],
                        // shiftDoctorHours: currentlySelectedClinicCalendar?.clinicDoctorHours === "" ? [] : [dayjs(currentlySelectedClinicCalendar?.clinicDoctorHours[0], 'HH:mm'), dayjs(currentlySelectedClinicCalendar?.clinicDoctorHours[1], 'HH:mm')],
                        shiftDoctorHours: currentlySelectedClinicCalendar?.clinicDoctorHours === "" ? [] : [currentlySelectedClinicCalendar?.clinicDoctorHours[0], currentlySelectedClinicCalendar?.clinicDoctorHours[1]],
                    });
                }
            }
        };
    
        fetchDefaultValues();

    }, [shiftDate, isCreateOrEditShiftModalOpen, isEdittingExistingShift]);

    useEffect(() => {

        // Only run the below when the modal is opening.. not when it is closing..
        if(isCreateOrEditShiftModalOpen){
            // OPENING EXISITNG SHIFT //
            if (currentlySelectedShiftToEdit.clinicId != undefined) {
                console.log('EDITING AN EXISTING SHIFT');
                // console.log("useEffect Ran: doctorListLoaded: ", doctorListLoaded);
                sessionStorage.setItem("currentlyEdittingInformation", true); // don't allow re-pull data

                // Only show loading spinner on the first load.
                if(!doctorListLoaded){
                    setDoctorListLoading(true);
                    // console.log("setDoctorListLoading(true)");
                }
                // console.log('Opened the CreateOrEditShift Modal with a specific shift id to edit supplied. Edit an existing shift.');
                console.log('selectedShiftToEdit: ', currentlySelectedShiftToEdit);
                // console.log("currentlySelectedClinicCalendar: ", currentlySelectedClinicCalendar);
                setIsEdittingExistingShift(true);
                handleStateChangeOnShiftEdit(currentlySelectedShiftToEdit);

            } 
            // CREATING NEW SHIFT //
            else {
                console.log('CREATING A NEW SHIFT - open creating shift modal');
                console.log("currentlySelectedClinicCalendar to create new shift with clinic defaults: ", currentlySelectedClinicCalendar);
                setActiveTabKey('1');
                setIsEdittingExistingShift(false);
                sessionStorage.setItem("currentlyEdittingInformation", true); // don't allow re-pull data
                // now based on this currate this modal
                handleStateChangeOnCreateNewShift();
            }
        }

        // REFACTOR.. Need more robust mechanism for this logic.. down on line 850
        // sessionStorage.setItem("doctorWasAcceptedDontShowAcceptButtons", false);

    }, [isCreateOrEditShiftModalOpen, shiftsWithInterestedDoctors, currentlySelectedShiftToEdit]);

    // toast notification
    const openNotification = (message) => {
        api.open({
            message: message,
            icon: message === "Shift Creation Failed" ? 
            <CloseCircleTwoTone style={{ color: '#108ee9' }} /> 
            : 
            <CheckCircleTwoTone style={{ color: '#108ee9' }} />,
        });
    };

    // open modal for Edit shift flow
    const handleStateChangeOnShiftEdit = async (selectedShiftToEdit) => {

        // set initial modal values
        setShiftId(selectedShiftToEdit.shiftId);
        setShiftType(selectedShiftToEdit.shiftType);
        setShiftDate(selectedShiftToEdit.shiftDate);
        if(selectedShiftToEdit.shiftDoctorHours !== undefined){
            // console.log("selectedShiftToEdit.shiftDoctorHours: ", selectedShiftToEdit.shiftDoctorHours);
            // console.log("should SET setShiftDoctorHoursSimpleArray and setShiftDoctorHours - within handleStateChangeOnShiftEdit");

            // Need to format the doctor hours to be used in the dropdown..
            setShiftDoctorHoursSimpleArray(selectedShiftToEdit.shiftDoctorHours); // to send to dynamo
            setShiftDoctorHours([dayjs(selectedShiftToEdit.shiftDoctorHours[0], 'HH:mm'), dayjs(selectedShiftToEdit.shiftDoctorHours[1], 'HH:mm')]); 
        }else{
            console.log("selectedShiftToEdit.shiftDoctorHours === undefined");

            // console.log("should CLEAR setShiftDoctorHoursSimpleArray and setShiftDoctorHours - within handleStateChangeOnShiftEdit");
            setShiftDoctorHoursSimpleArray([]);
            setShiftDoctorHours([]);

        }
        setShiftPay(selectedShiftToEdit.shiftPay);
        setShiftNotes(selectedShiftToEdit.shiftNotes);
        setIsRecurring(selectedShiftToEdit.isRecurring); 
        setIsGlassesAndContacts(selectedShiftToEdit.isGlassesAndContacts);
        setIsMedical(selectedShiftToEdit.isMedical);

        setShiftRecurringSelection(selectedShiftToEdit.shiftRecurringSelection);

        // set values for react-hook-form to use form validation in default values
        setValue('shiftPayment', selectedShiftToEdit.shiftPay, { shouldValidate: true });
        setValue('isRecurring', selectedShiftToEdit.isRecurring, { shouldValidate: true });

        setValue('isGlassesAndContacts', selectedShiftToEdit.isGlassesAndContacts, { shouldValidate: true })
        setValue('isMedical', selectedShiftToEdit.isMedical, { shouldValidate: true })

        setValue('recurringOptions', selectedShiftToEdit.shiftRecurringSelection, { shouldValidate: true });
        setValue('shiftType', selectedShiftToEdit.shiftType, { shouldValidate: true });


        // Now check if any of the items in the shiftsWithInterestedDoctors have a shiftId that matches selectedShiftToEdit.shiftId
        // if so, add them to a new array interestedDoctorTableData
        const interestedDoctorsArray = [];
        for(var i=0; i<shiftsWithInterestedDoctors.length; i++){
            // console.log("shiftsWithInterestedDoctors[i].shiftId: ", shiftsWithInterestedDoctors[i].shiftId);
            // console.log("selectedShiftToEdit.shiftId: ", selectedShiftToEdit.shiftId);

            if(shiftsWithInterestedDoctors[i].shiftId == selectedShiftToEdit.shiftId){

                // console.log("Current shift selected: " + selectedShiftToEdit.shiftId + " matches a shift that has an interestedDoctor: " + shiftsWithInterestedDoctors[i].shiftId);
                // switch to doctor list tab
                setActiveTabKey('2');
                sessionStorage.setItem("currentlyEdittingInformation", false); // allow re-pull data

                // now we know which doctors are interested in this shift.
                // console.log("These are the interested doctors for this shift: ", shiftsWithInterestedDoctors[i].interestedDoctors);
                for(var j=0; j<shiftsWithInterestedDoctors[i].interestedDoctors.length; j++){
                    // console.log("shiftsWithInterestedDoctors[i].interestedDoctors[j]: ", shiftsWithInterestedDoctors[i].interestedDoctors[j]);
                    const user =  await getUserData(shiftsWithInterestedDoctors[i].interestedDoctors[j].doctorId);

                    // make sure that the user still exists.. if the users account is deleted.. throws an error here..
                    if (user){
                        const userParsed = JSON.parse(user.body);
                        // console.log("userParsed: ", userParsed);

                        // Format the object for use with Ant Design Table
                        userParsed.key = j; // give each doctor a key.
                        userParsed.name = userParsed?.userData?.firstName + " " + userParsed.userData?.lastName;
                        userParsed.userId = userParsed?.userData?.userId;


                        // We need to know if the user has been accepted for this shift..
                        // console.log("shiftsWithInterestedDoctors[i]: ", shiftsWithInterestedDoctors[i]);
                        if (shiftsWithInterestedDoctors[i].acceptedDoctor?.doctorId == userParsed?.userData?.userId) {
                            userParsed.tags = ['accepted'];
                        } else {
                            userParsed.tags = ['not accepted'];
                        }

                        //If a doctor has been accepted for this shift, take away accept action button..
                        interestedDoctorsArray.push(userParsed);
                    }
                }

                await delay(700);
                // Switch to that tab when opening the modal
                setDoctorListLoading(false);
                // console.log("setDoctorListLoading(false)");
                setDoctorListLoaded(true);
                // console.log("setDoctorListLoaded(true)");

                // console.log("interestedDoctors for this shift: ", interestedDoctorsArray);
                setInterestedDoctorTableData(interestedDoctorsArray);
            }
        }

        // If there are no shiftsWithInterestedDoctors, stop loading.
        setDoctorListLoading(false);
        // console.log("setDoctorListLoading(false)");
    };

    function delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }

    // get user data in loop above
    async function getUserData(userId) {
        try {
          return API.post("od-api", "/getUserData", {
            body: {userId: userId},
          }).then((data) => {
    
            // console.log("get user data response: ", data);
            // do things
            return data;
    
          });
        } catch (e) {
          onError(e);
          console.log("ERROR: ", e);
        }
    }

    // open model for create shift flow
    const handleStateChangeOnCreateNewShift = () => {
        console.log("currentlySelectedClinicCalendar to create new shift with clinic defaults: ", currentlySelectedClinicCalendar);

        // set initial modal values
        setShiftNotes("");
        setShiftType("singleShift");
        setShiftDate(selectedCalendarDate); 
        setClinicId(currentlySelectedClinicCalendar?.clinicId);
        setClinicName(currentlySelectedClinicCalendar?.clinicName);
        setShiftPay(currentlySelectedClinicCalendar?.clinicPay);
        setValue('shiftPayment', currentlySelectedClinicCalendar?.clinicPay, { shouldValidate: true });
        setIsGlassesAndContacts(currentlySelectedClinicCalendar?.clinicGlassesContactsBool);
        setIsMedical(currentlySelectedClinicCalendar?.clinicMedicalBool);

        if(currentlySelectedClinicCalendar?.clinicDoctorHours !== ""){
            console.log("currentlySelectedClinicCalendar.clinicDoctorHours: ", currentlySelectedClinicCalendar?.clinicDoctorHours);

            // console.log("should SET setShiftDoctorHoursSimpleArray and setShiftDoctorHours - within handleStateChangeOnCreateNewShift");
            setShiftDoctorHoursSimpleArray(currentlySelectedClinicCalendar?.clinicDoctorHours); // to send to dynamo
            setShiftDoctorHours([dayjs(currentlySelectedClinicCalendar?.clinicDoctorHours[0], 'HH:mm'), dayjs(currentlySelectedClinicCalendar?.clinicDoctorHours[1], 'HH:mm')]); 
        }else{
            console.log("currentlySelectedClinicCalendar?.clinicDoctorHours === <empty>");

            // console.log("should CLEAR setShiftDoctorHoursSimpleArray and setShiftDoctorHours - within handleStateChangeOnCreateNewShift");
            setShiftDoctorHoursSimpleArray([]);
            setShiftDoctorHours([]);
        }

    };

    const handleShiftPayChange = (event) => {
        // console.log("event.target.id: ", event.target.value);
        setShiftPay(event.target.value);
        // setValue('shiftPayment', event.target.value, { shouldValidate: true });
    };

    const handleRecurringChange = (checked) => {
        // if we are not editing an existing shift and the user toggles recurring to true, populate the default as daily.
        if(checked == true){
            setShiftRecurringSelection("Daily");
        }
        setIsRecurring(checked);
        // setValue('isRecurring', checked, { shouldValidate: true });
    };

    const handleRecurringSelectChange = (value) => {
        setShiftRecurringSelection(value);
        // setValue('recurringOptions', value, { shouldValidate: true });
    };

    const handleShiftTypeChange = (value) => {
        setShiftType(value);
        // setValue('shiftType', value, { shouldValidate: true });
    };

    const handleShiftDoctorHoursStartTimeChange = (value) => {
        console.log("start time value: ", value.value);
        setShiftDoctorHours_StartTime(value.value);
        console.log("shiftDoctorHours_EndTime: ", shiftDoctorHours_EndTime);
        // console.log("shiftDoctorHoursSimpleArray: ", shiftDoctorHoursSimpleArray);


        if (shiftDoctorHours_EndTime !== ""){
            // console.log("updating shiftDoctorHoursSimpleArray with value.value and shiftDoctorHours_EndTime");
            setShiftDoctorHoursSimpleArray([value.value, shiftDoctorHours_EndTime]); 
        } else if (shiftDoctorHoursSimpleArray[1] !== "") {
            // console.log("updating shiftDoctorHoursSimpleArray with value.value and shiftDoctorHoursSimpleArray[1]");
            setShiftDoctorHoursSimpleArray([value.value, shiftDoctorHoursSimpleArray[1]]);
        }
    };

    const handleShiftDoctorHoursEndTimeChange = (value) => {
        console.log("end time value: ", value.value);
        setShiftDoctorHours_EndTime(value.value);
        // console.log("shiftDoctorHoursSimpleArray: ", shiftDoctorHoursSimpleArray);

        console.log("shiftDoctorHours_StartTime: ", shiftDoctorHours_StartTime);
        if (shiftDoctorHours_StartTime !== ""){
            // console.log("updating shiftDoctorHoursSimpleArray with shiftDoctorHours_StartTime and value.value");
            setShiftDoctorHoursSimpleArray([shiftDoctorHours_StartTime, value.value]);
        } else if (shiftDoctorHoursSimpleArray[0] !== ""){
            // console.log("updating shiftDoctorHoursSimpleArray with shiftDoctorHoursSimpleArray[0] and value.value");
            setShiftDoctorHoursSimpleArray([shiftDoctorHoursSimpleArray[0], value.value]);
        }
    };

    const openEditAcceptedDoctorModal = (event) => {
        // console.log('Button clicked!');
        console.log('Event:', event);
        setIsEditAcceptedDoctorModalOpen(true);
    };

    const closeEditAcceptedDoctorModal = () => {
        setIsEditAcceptedDoctorModalOpen(false);
    }

    // Confirm Doctor Cancel Shift - From Doctor Calendar 
    const openDoctorCancelShiftConfirmationModal = () => {
        setIsConfirmDoctorCancelShiftModalOpen(true);
    }

    const closeConfirmDoctorCancelShiftModal = () => {
        setIsConfirmDoctorCancelShiftModalOpen(false);
    }

    const openAcceptDoctor = (event) => {
        console.log('Clicked Accept Button!');
        console.log('CurrentlySelectedDoctorToAcceptForShift:', event);
        setCurrentlySelectedDoctorToAcceptForShift(event);
        setIsOpenAcceptDoctorModal(true);
    };

    const acceptDoctor = async () => {

        setDoctorListLoading(true);
        setConfirmAcceptDoctorButtonLoading(true);
        console.log("accept doctor.. Change the shiftState to 'SCHEDULED'");
        console.log("currentlySelectedDoctorToAcceptForShift: ", currentlySelectedDoctorToAcceptForShift);

        // Need to pass in some additional information below to send via email to the accepted doctor.
        const acceptDoctorForShiftParams = {
            clinicId: clinicId, 
            shiftId: getShiftIdForEditOrCreate(),
            shiftState: "Scheduled",
            acceptedDoctorId: currentlySelectedDoctorToAcceptForShift.userId,
            acceptedDoctorName: currentlySelectedDoctorToAcceptForShift.firstName + " " + currentlySelectedDoctorToAcceptForShift.lastName, // THIS SHOULD BE THE DOCTOR NAME... NOT userData NAME...
            shiftDate: currentlySelectedShiftToEdit.shiftDate,
            shiftHours: currentlySelectedShiftToEdit.shiftDoctorHours,
            clinicName: currentlySelectedShiftToEdit.clinicData.clinicName,
            clinicAddress: currentlySelectedShiftToEdit.clinicData.clinicAddress,
            doctorEmail: currentlySelectedDoctorToAcceptForShift.email
        }
        console.log("acceptDoctorForShiftParams for accept doctor: ", acceptDoctorForShiftParams);

        try {
            return API.post("od-api", "/shiftData", {
                body: acceptDoctorForShiftParams,
            }).then( async (data) => {
            console.log("create or update shift data response: ", data);
            if(data.statusCode === 200){ // check response to see if succesful or not.
                
                openNotification('Accepted Doctor Succesfully');
                const parsedBody = JSON.parse(data.body);
                console.log("accept doctor response: ", parsedBody);

                setConfirmAcceptDoctorButtonLoading(false);
                setIsOpenAcceptDoctorModal(false);
                setIsDoctorInformationModalOpen(false);

                // Re-pull data
                rePullClinicData(clinicId);
                await onLoginEvent();
                setDoctorListLoading(false);

            }else{
                openNotification('Failed To Accept Doctor');

                setConfirmAcceptDoctorButtonLoading(false);
                setIsOpenAcceptDoctorModal(false);
                setDoctorListLoading(false);
            }
            });
        } catch (e) {
            onError(e);
            console.log("ERROR: ", e);
        }

    }

    const openDoctorInformationModal = (event) => {
        console.log('Clicked openDoctorInformationModal Button!');
        console.log('Event:', event);

        setIsDoctorInformationModalOpen(true);
        setCurrentlySelectedDoctorInformation(event.userData);
    };

    const openClinicInformationModal = () => {
        console.log("clicked openClinicInformationModal");
        console.log("currentlySelectedShiftToEdit?.clinicData: ", currentlySelectedShiftToEdit?.clinicData);

        setIsClinicInformationModalOpen(true);
        setCurrentlySelectedClinicInformation(currentlySelectedShiftToEdit?.clinicData);
    }

    function closeModal() {

        // repull data
        onLoginEvent(true); // if you set this to true, you turn off loading screen, but then see jump between calendars...
        rePullClinicData(clinicId);
        handleCancel();

        setShiftDoctorHours_StartTime("");
        setShiftDoctorHours_EndTime("");

        // Reset the modal to defaults
        reset(defaultValues);
        setActiveTabKey('1');

        setDoctorListLoading(false);
        setDoctorListLoaded(false);

        // clear doctor applicants table
        setInterestedDoctorTableData([]);
        sessionStorage.setItem("currentlyEdittingInformation", false);
    }

    function closeClinicInformationModal(){
        setIsClinicInformationModalOpen(false);
    }

    function closeDoctorInformationModal(){
        setIsDoctorInformationModalOpen(false);
    }

    function closeDeleteConfirmationModal() {
        setIsDeleteConfirmationModalOpen(false);
    }

    function closeMarkShiftAsScheduledExternallyConfirmationModal() {
        setIsMarkShiftAsScheduledExternallyConfirmationModalOpen(false);
    }

    function closeAcceptDoctorModal(){
        setIsOpenAcceptDoctorModal(false);
    }

    // react-hook-form submit
    const onSubmit = data => {
        // Data is the root object of the form data
        // console.log("react-hook-form-data submitted object: ", data);
        createOrUpdateShift();
    };

    // Tabs
    const onTabChange = (key) => {
        console.log("Tab Changed: ", key);
        // do things
        setActiveTabKey(key);

        if(key === "1"){
            console.log("should set setCurrentlyEdittingInformation is sessionStorage to true");
            sessionStorage.setItem("currentlyEdittingInformation", true);
        }else if(key === "2"){
            console.log("should set setCurrentlyEdittingInformation is sessionStorage to false");
            sessionStorage.setItem("currentlyEdittingInformation", false);
        }

    };

    // NEED TO USE REACT-HOOK-FORM....
    // Checkbox for Glasses and Contacts vs. Medical
    const onGlassesCheckboxChange = (e) => {
        // console.log(`Glasses & Contacts checked = ${e.target.checked}`);
        setIsGlassesAndContacts(e.target.checked);
    };

    const onMedicalCheckboxChange = (e) => {
        // console.log(`Medical checked = ${e.target.checked}`);
        setIsMedical(e.target.checked);
    };

    function LocalDateStringFromSimpleDate(dateString) {
        const date = new Date(`${dateString}T00:00:00`);

        // console.log("dateString: ", dateString);
        // console.log("date: ", date);
    
        // Convert the Date object to a localized string including the day of the week
        const localizedDateString = date.toLocaleDateString('en-US', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        });
        
        // console.log(localizedDateString); // Output: "Saturday, July 27, 2024" (in US English locale)
        return localizedDateString
    }
    
    function formatTimeRange(timeRange) {

        // console.log("timeRange: ", timeRange);

        if(timeRange != undefined){
            function convertTimeTo12HourFormat(time) {
                const [hour, minute] = time.split(':');
                const date = new Date();
                date.setHours(hour);
                date.setMinutes(minute);
            
                const options = {
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true
                };
            
                return date.toLocaleTimeString('en-US', options);
            }
        
            const startTime = convertTimeTo12HourFormat(timeRange[0]);
            const endTime = convertTimeTo12HourFormat(timeRange[1]);
            return `${startTime} - ${endTime}`;
        }
    }

    function checkIfShiftDoctorHoursArePopulated (){
        // console.log("checking if doctor hours have ben populated");
        // console.log("shiftDoctorHoursSimpleArray: ", shiftDoctorHoursSimpleArray);
        // console.log("shiftDoctorHours_StartTime: ", shiftDoctorHours_StartTime);
        // console.log("shiftDoctorHours_EndTime: ", shiftDoctorHours_EndTime);

        // need to make sure that both are filled out...
        // console.log("shiftDoctorHoursSimpleArray: ", shiftDoctorHoursSimpleArray);
        // console.log("shiftDoctorHoursSimpleArray.length : ", shiftDoctorHoursSimpleArray.length );

        if (shiftDoctorHoursSimpleArray.length > 0 && shiftDoctorHoursSimpleArray[0] !== undefined && shiftDoctorHoursSimpleArray[1] !== undefined ){
            return true
        }else{
            return false
        }
    }

    // REFACTOR - Pull out these utilities - On doctor calendar
    function checkIfDoctorWasAcceptedOrNot(currentlySelectedShiftToEdit){
        // console.log("currentlySelectedShiftToEdit: ", currentlySelectedShiftToEdit);
        let status = "";

        if(currentlySelectedShiftToEdit?.shiftState?.toUpperCase() === "SCHEDULED"){
            if(currentlySelectedShiftToEdit?.acceptedDoctor?.doctorId === userData.userId){
              status = currentlySelectedShiftToEdit?.shiftState?.toUpperCase()
            }else{
              status = "NOT ACCEPTED"
            }
          }else{
            status = currentlySelectedShiftToEdit?.shiftState?.toUpperCase()
        }

        return status
    }

    // REFACTOR - Pull out these utilities
    function getColorForTagIfDoctorWasAcceptedOrNot(currentlySelectedShiftToEdit){
        // console.log("currentlySelectedShiftToEdit: ", currentlySelectedShiftToEdit);
        let color = "";

        if(currentlySelectedShiftToEdit?.acceptedDoctor?.doctorId === userData.userId){
            color = "#D3F7F8"
        }else if(currentlySelectedShiftToEdit.shiftState === "Pending"){
            color = "#fff7e6"
        }
        else{
            color = "#ffffff"
        }
        return color
    }


    // REFACTOR ACCEPTING DOCTOR TABLE //
    const columns = [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          render: (_, record) => (<a 
            style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => openDoctorInformationModal(record)}>{"Dr. " + record.userData.firstName + " " + record.userData.lastName}</a>),
        },
        {
          title: 'Status',
          key: 'tags',
          dataIndex: 'tags',
          render: (_, { tags }) => (
            <>
              {tags.map((tag) => {
                let color;
                if (tag == 'accepted'){
                    color = 'blue'
                } else if (tag == 'not accepted'){
                    color = 'red'
                }

                return (
                  <Tag color={color} key={tag}>
                    {tag.toUpperCase()}
                  </Tag>
                );
              })}
            </>
          ),
        },
        {
          title: 'Action',
          key: 'action',
          render: (_, record) => (
            <>
              {/* Check if the tags array includes "accepted", then display the buttons */}
              {record.tags.includes('accepted') ? (
                <Space>
                    <Button className="secondary-button button-size-default" onClick={() => openEditAcceptedDoctorModal(record.userId)}>Edit</Button>
                </Space>
                ) : (
                <Space>

                    {/* REFACTOR */}
                    { currentlySelectedShiftToEdit?.acceptedDoctor === undefined ?
                        <Button className="secondary-button button-size-default" onClick={() => openAcceptDoctor(record.userData)}>Accept</Button>
                    : 
                        // don't show either Accept, nor Edit buttons
                        <></>
                    }

                </Space>
               )}
            </>
          ),

        },
    ];

    return(
        <>
            {contextHolder}
            <Modal
                open={isCreateOrEditShiftModalOpen}
                onOk={handleSubmit(onSubmit)}
                // title={isEdittingExistingShift ? 'Edit Shift ' : 'Add a New Open Shift' }
                onCancel={closeModal}
                centered
                footer={ 
                    <>
                        {userData.userType == 'clinicTeamMember' ? 
                        <>
                            {/* TODO: If edittingEsistingShift && on "Doctor Applications" tab,  */}
                            {/* Just have close button, dont show delete shift button and update shift button. */}

                            {/* Add Delete Button if editing a shift */}
                            {activeTabKey === '1' ? 
                                isEdittingExistingShift?
                                    <>
                                        <Button 
                                            key="markScheduledExternally" 
                                            className="secondary-button button-size-default floatLeft" 
                                            style={{ 
                                                color: '#cc2900',
                                             }} 
                                            onClick={markShiftScheduledExternallyConfirmation}
                                        >
                                            Mark Scheduled Externally
                                        </Button>
                                        <Button key="deleteShift" className="error-button button-size-default" style={{ color: '#cc2900' }} onClick={deleteShiftConfirmation}>
                                            Delete Shift
                                        </Button>
                                    </>
                                :
                                    <Button key="cancelShift" className="secondary-button button-size-default" onClick={closeModal}>
                                        Cancel
                                    </Button>
                            : 
                                <></>
                            }

                            {activeTabKey === '1' ? 
                                <Button key="addShift" htmlType="submit" className="primary-button button-size-default" onClick={handleSubmit(onSubmit)} disabled={shiftDoctorHoursSimpleArray.length === 0 || validateCreateShift()}>
                                    {isEdittingExistingShift ? 'Update Shift' : 'Add Shift'}
                                </Button>
                            : 
                                <>
                                    <Button
                                        key="markScheduledExternally"
                                        className="secondary-button button-size-default floatLeft"
                                        style={{
                                            color: '#cc2900',
                                        }}
                                        onClick={markShiftScheduledExternallyConfirmation}
                                    >
                                        Mark Scheduled Externally
                                    </Button>
                                    <Button key="addShift" htmlType="submit" className="primary-button button-size-default" onClick={closeModal}>
                                        Close
                                    </Button>
                                </>
                            }
                        </>
                        :
                        <></>
                    }
                    </>

                }
            >        
                {/* DISPLAY SHIFT ID, CLINIC ID, and CLINIC NAME AT TOP OF CREATE/EDIT SHIFT MODAL */}
                {/* <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>

                    {isEdittingExistingShift ? <p style={{marginBottom: '0px'}}><b>Shift Id: </b>{shiftId}</p> : <></> }

                    {userData.userType == 'clinicTeamMember' ?
                    <>
                        <p style={{marginBottom: '0px'}}><b>Clinic Id: </b>{clinicId}</p>
                        <p style={{marginBottom: '30px'}}><b>Clinic Name: </b>{clinicName}</p> 
                    </>
                    : 
                    <></>
                    }

                </Form> */}

                {/* Tabs */}
                
                {/* If the user is a clinicTeamMember, populate the modal with create or edit layout */}
                {userData.userType == 'clinicTeamMember' ?
                    <Tabs 
                    style={{marginTop: '-10px'}}
                    activeKey={activeTabKey}
                    items={
                        [
                            {
                            key: '1',
                            label: isEdittingExistingShift ? 'Edit Shift' : 'Create New Open Shift' , 
                            children: 
                                // *Input forms
                                <Form 
                                autoComplete="off"
                                >
                                    <Row gutter={ [0 , 20] }>
                                        <Col span={24} style={{marginBottom: isMobile ? '-30px' : '0px'}}>
                                            <Form.Item 
                                                    label="Shift Type" 
                                                    layout="vertical" 
                                                    validateStatus={errors.shiftType ? 'error' : ''} help={errors.shiftType && 'Shift Type is required'}>
                                                <Controller
                                                    name="shiftType"
                                                    control={control}
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <Select
                                                            style={{ marginTop: -6 }}
                                                            {...field}
                                                            placeholder="What type of shift do you need?"
                                                            onChange={(value) => {
                                                                field.onChange(value);
                                                                handleShiftTypeChange(value);
                                                            }}
                                                            allowClear={false}
                                                            value={shiftType}
                                                        >
                                                        <Option value="singleShift">Single Day</Option>
                                                        <Option value="partTime" disabled>Part-time</Option>
                                                        <Option value="fullTime" disabled>Full-time</Option>
                                                        </Select>
                                                    )}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col span={24} style={{marginBottom: isMobile ? '-30px' : '0px'}}>
                                            <Form.Item label="Date" 
                                                    layout="vertical" 
                                                    validateStatus={errors.shiftDate ? 'error' : ''} 
                                                    help={errors.shiftDate && 'Date is required'} >
                                                <Controller
                                                    name="shiftDate"
                                                    control={control}
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <DatePicker
                                                                style={{ marginTop: -6 }}
                                                            {...field}
                                                            showNow={false}
                                                            allowClear={false}
                                                            onChange={(value, dateString) => {
                                                                field.onChange(value);
                                                                console.log("adjusted new date: " + dateString);
                                                                setShiftDate(dateString);
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24} style={{marginBottom: isMobile ? '-10px' : '30px'}}>
                                            <Form.Item label="Doctor Hours" 
                                                    layout="vertical" 
                                                    // validateStatus={errors.shiftDoctorHours ? 'error' : ''} 
                                                    // help={errors.shiftDoctorHours && 'Hours required'}
                                                >
                                                    <>
                                                        {/* Start Time */}
                                                        <Select
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) =>
                                                                option?.label.toLowerCase().includes(input.toLowerCase())
                                                            }
                                                            labelInValue
                                                            style={{ width: 140, marginRight: '12px' }}
                                                            placeholder="start time"
                                                            options={doctorHourTimes}
                                                            value={shiftDoctorHours_StartTime === "" ? shiftDoctorHoursSimpleArray[0] : shiftDoctorHours_StartTime}
                                                            onChange={(value) => {
                                                                handleShiftDoctorHoursStartTimeChange(value);
                                                            }}
                                                        />
                                                        {/* End Time */}
                                                        <Select
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) =>
                                                                option?.label.toLowerCase().includes(input.toLowerCase())
                                                            }
                                                            labelInValue
                                                            style={{ width: 140 }}
                                                            placeholder="end time"
                                                            options={doctorHourTimes}
                                                            value={shiftDoctorHours_EndTime === "" ? shiftDoctorHoursSimpleArray[1] : shiftDoctorHours_EndTime}
                                                            onChange={(value) => {
                                                                handleShiftDoctorHoursEndTimeChange(value);
                                                            }}
                                                        />
                                                    </>
                                            </Form.Item>
                                        </Col>
                                        <p style={{ color: 'red', marginTop: '-40px', fontSize: '14px' }} hidden={checkIfShiftDoctorHoursArePopulated()}>Hours Required</p>
                                        
                                        <Col span={24} style={{ marginBottom: isMobile ? '-10px' : '0px', marginTop: errors.shiftDoctorHours ? "0px" : "-30px" }}>
                                            <Form.Item label="Recurring"
                                                valuePropName="checked">
                                                <Controller
                                                    name="isRecurring"
                                                    control={control}
                                                    render={({ field }) => ( 
                                                        <>
                                                            <div style={{marginTop: isMobile ? '-24px' : '0px'}}>
                                                                <Switch
                                                                    {...field}
                                                                    checked={isRecurring}
                                                                    disabled
                                                                    onChange={(checked) => {
                                                                        field.onChange(checked);
                                                                        handleRecurringChange(checked);
                                                                    }}
                                                                />
                                                                <Tag 
                                                                    color="blue" 
                                                                    style={{ marginLeft: '12px'}}
                                                                    >Coming Soon</Tag>
                                                            </div>
                                                        </>

                                                    )}
                                                />
                                            </Form.Item>
                                        </Col>

                                        {isRecurring && (
                                        <Col span={24} style={{marginBottom: '20px', marginTop: isMobile ? '-20px' : '0px'}}>
                                            <Form.Item label="" 
                                                        layout="vertical"
                                                        >
                                                <Controller
                                                    name="recurringOptions"
                                                    control={control}
                                                    render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        placeholder="Select recurrence pattern"
                                                        onChange={(value) => {
                                                            field.onChange(value);
                                                            handleRecurringSelectChange(value);
                                                        }}
                                                        value={shiftRecurringSelection}
                                                    >
                                                        <Option value="daily">Daily</Option>
                                                        <Option value="weekly">Weekly</Option>
                                                        <Option value="monthly">Monthly</Option>
                                                    </Select>
                                                    )}
                                                />
                                            </Form.Item>
                                        </Col>
                                        )}

                                        {/* ROUTINE and MEDICAL  */}
                                        <div style={{marginTop: isMobile ? '-30px' : '-30px'}}>

                                            <p style={{ 
                                                marginRight: '16px', 
                                                fontSize: '14px',
                                            }}>
                                            Exam type:
                                            </p>

                                            <Row gutter={16} style={{ marginTop: '-20px'}} >
                                                <Col>
                                                    <Form.Item label="" valuePropName="">
                                                    <Controller
                                                        name="isGlassesAndContacts"
                                                        control={control}
                                                        render={({ field }) => (
                                                        <>
                                                            <Checkbox 
                                                                {...field}
                                                                checked={isGlassesAndContacts} 
                                                                onChange={onGlassesCheckboxChange}
                                                            >
                                                            Routine
                                                            </Checkbox> 
                                                        </>
                                                        )}
                                                    />
                                                    </Form.Item>
                                                </Col>

                                                <Col>
                                                    <Form.Item label="" valuePropName="">
                                                    <Controller
                                                        name="isMedical"
                                                        control={control}
                                                        render={({ field }) => (
                                                        <>
                                                            <Checkbox 
                                                                {...field}
                                                                checked={isMedical} 
                                                                onChange={onMedicalCheckboxChange}
                                                            >
                                                            Medical
                                                            </Checkbox>
                                                        </>
                                                        )}
                                                    />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </div>


                                        <Col span={24}>
                                            <Form.Item
                                                style={{ marginTop: isMobile ? '-30px' : '-30px' }}
                                                label="Pay ($USD)"
                                                layout="vertical"
                                                validateStatus={errors.shiftPayment ? 'error' : ''}
                                                help={errors.shiftPayment && 'Pay is required'}
                                            >
                                                <Controller
                                                    name="shiftPayment"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Input
                                                            style={{ marginTop: -6}}
                                                            {...field}
                                                            placeholder="($)"
                                                            type="number"
                                                            onChange={(e) => {
                                                                field.onChange(e);
                                                                handleShiftPayChange(e);
                                                            }}
                                                            value={shiftPay}
                                                            onWheel={(e) => e.target.blur()}
                                                        />
                                                    )}
                                                />
                                            </Form.Item>
                                        </Col>


                                        <Col span={24} style={{marginBottom: isMobile ? '-16px' : '48px', marginTop: isMobile ? '-24px' : '0px'}}>
                                            <Form.Item label="Add Notes (optional)" layout="vertical">
                                                <Controller
                                                    name="shiftNotes"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextArea
                                                            style={{ marginTop: -6 }}
                                                            {...field}
                                                            rows={2}
                                                            placeholder="add notes"
                                                            value={shiftNotes}
                                                            maxLength={600}
                                                            onChange={(e) => {
                                                                field.onChange(e);
                                                                setShiftNotes(e.target.value);
                                                            }}
                                                        />
                                                    )}
                                                />
                                            
                                            </Form.Item>
                                        </Col>

                                        {/* 
                                        <Form.Item label="Request Doctor(s)">
                                            <Controller
                                                name="inviteDoctors"
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                    {...field}
                                                    mode="multiple"
                                                    maxCount={4}
                                                    // disabled
                                                    value={inviteDoctorsValue}
                                                    style={{ width: '100%' }}
                                                    onChange={setInviteDoctorsValue}
                                                    suffixIcon={<><span>{inviteDoctorsValue.length} / 4 </span><DownOutlined /></>}
                                                    placeholder="request doctor(s)"
                                                    options={[
                                                        { value: 'Doctor Swift', label: 'Doctor Swift' },
                                                        { value: 'Doctor Reed', label: 'Doctor Reed' },
                                                        { value: 'Doctor Blake', label: 'Doctor Blake' },
                                                        { value: 'Doctor Stone', label: 'Doctor Stone' }
                                                    ]}
                                                    />
                                                )}
                                            />
                                        </Form.Item> */}
                                    </Row>
                                
                                </Form>,
                            },
                            isEdittingExistingShift ?  
                            {
                                key: '2',
                                label: 'Doctor Applications',
                                children: 
                                
                                // List of Doctors that have applied for the shift
                                <>
                                    {/* <Table columns={columns} dataSource={interestedDoctorTableData} size="small" /> */}

                                    {/* Add shift date to this Tab in the modal, if clinic is "viewing doctor" from the notifications panel */}
                                    {/* Should make it clear which shift they are currently looking at, as they did not select on the calendar. */}
                                    <p>Shift Date: <b>{currentlySelectedShiftToEdit.shiftDate}</b></p>

                                    {interestedDoctorTableData?.length > 0 ? (
                                        doctorListLoading ? 
                                        <Spin style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop:'80px', marginBottom:'80px' }} size="large" />
                                        : 
                                        <Table columns={columns} dataSource={interestedDoctorTableData} size="small" />
                                    ) : (
                                        doctorListLoading ? 
                                        <Spin style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop:'80px', marginBottom:'80px' }} size="large" />
                                        : 
                                        <p style={{ marginBottom: '40px', marginTop: '40px', textAlign: 'center', fontWeight: 'bold' }}>No doctors have applied yet.</p>
                                    )}
                                </>
                                
                                ,
                            }
                            :
                            {} // if not editing an existing shift, dont show the second tab.
                        ]
                    } 
                    onChange={onTabChange} 
                />
                : 
                    //// SHIFT INFORMATION MODAL ON DOCTOR CALENDAR ////
                    // If the user is a doctor, populate the modal with different information and layout
                    <>
                        {currentlySelectedShiftToEdit != undefined ?
                            <>
                                <Row style={{marginTop: '6px'}}>
                                    <Col span={20}>
                                    <p style={{ marginTop: '-8px' }}>
                                        {LocalDateStringFromSimpleDate(currentlySelectedShiftToEdit.shiftDate)}
                                    </p>
                                    <p
                                        style={{
                                        color: 'grey',
                                        marginTop: '-16px',
                                        fontSize: '14px',
                                        }}
                                    >
                                        {formatTimeRange(currentlySelectedShiftToEdit.shiftDoctorHours)}
                                    </p>
                                    </Col>
                                    <Col span={4}>
                                    <p style={{ float: 'right', marginRight: '30px', marginTop: '-10px' }}>${currentlySelectedShiftToEdit.shiftPay}</p>
                                    </Col>
                                </Row>
                                <hr style={{ marginTop: '-10px', color:'#BFBFBF' }}></hr>
                                <Row style={{ marginTop: '-5px' }}>
                                    <Col span={20}>
                                    {currentlySelectedShiftToEdit.isGlassesAndContacts ? (
                                        <Tag
                                        color={'#F8EFFF'}
                                        style={{ color: 'black', fontSize: '10px' }}
                                        >
                                        ROUTINE
                                        </Tag>
                                    ) : null}
                                    {currentlySelectedShiftToEdit.isMedical ? (
                                        <Tag
                                        color={'#F8EFFF'}
                                        style={{ color: 'black', fontSize: '10px' }}
                                        >
                                        MEDICAL
                                        </Tag>
                                    ) : null}
                                    </Col>
                                    <Col span={4}>

                                    {/* Shift State Tag in Shift Card ("Available", "Pending", "Scheduled") */}
                                    <Tag
                                        // color={ currentlySelectedShiftToEdit?.shiftState?.toUpperCase() == "PENDING" ?  '#fff7e6' : '#D3F7F8' } 
                                        color={getColorForTagIfDoctorWasAcceptedOrNot(currentlySelectedShiftToEdit)} 
                                        shift-state-tag={currentlySelectedShiftToEdit.shiftId + '_tag'}
                                        style={{
                                        color: 'black',
                                        fontSize: '10px',
                                        float: 'right',
                                        marginRight: '0px',
                                        }}
                                    >
                                        {checkIfDoctorWasAcceptedOrNot(currentlySelectedShiftToEdit)}
                                    </Tag>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '20px' }}>
                                    <Col span={24}>
                                        <div style={{marginBottom:'-26px'}}>

                                            {/* Need to get event and know which clinic is currently selected.. */}
                                            <p style={{ marginTop: '-8px', cursor: 'pointer', color: '#1677ff' }} 
                                               onClick={openClinicInformationModal}
                                               className="underline-on-hover"
                                               > 
                                                {currentlySelectedShiftToEdit?.clinicData?.clinicName}
                                            </p>
                                            <p
                                                style={{
                                                color: 'grey',
                                                marginTop: '-16px',
                                                fontSize: '14px',
                                                }}
                                            >
                                                {currentlySelectedShiftToEdit?.clinicData?.clinicAddress.split(',')[0]}
                                            </p>
                                            <p
                                                style={{
                                                color: 'grey',
                                                marginTop: '-16px',
                                                fontSize: '14px',
                                                }}
                                            >
                                                {currentlySelectedShiftToEdit?.clinicData?.clinicAddress
                                                .split(',')
                                                .slice(1)
                                                .join(',')}
                                            </p>
                                        </div>

                                        {currentlySelectedShiftToEdit?.shiftNotes != "" ? 
                                            <>
                                                <hr style={{ marginTop: '44px', color:'#BFBFBF' }}></hr>
                                                <p>Notes from the clinic</p>
                                                <p style={{fontSize:'14px', color:'#667284', marginBottom:'-10px', marginTop:'-10px'}}>{currentlySelectedShiftToEdit?.shiftNotes}</p>
                                            </>
                                        : 
                                            <></>
                                        }

                                    </Col>
                                    
                                </Row>
                                <Button key="cancelShift" className="error-button button-size-default" style={{ color: '#cc2900', float: 'right', marginTop: '-16px', marginRight: '-14px' }} onClick={openDoctorCancelShiftConfirmationModal}>
                                        Cancel Shift
                                    </Button>
                                <Row>
                                    
                                </Row>
                            </>
                        :
                            <></>
                        }


                    </>
                }

            </Modal>

            {/* Doctor Information Modal - From Clinic Calendar - Accept Doctor Flow */}
            <Modal
                title="Doctor Information"
                centered
                open={isDoctorInformationModalOpen} 
                onOk={closeDoctorInformationModal} 
                onCancel={closeDoctorInformationModal} 
                footer={
                    <>
                        <Button className="secondary-button button-size-default" onClick={closeDoctorInformationModal}>
                            Back    
                        </Button>
                        <Button className="primary-button button-size-default" onClick={() => openAcceptDoctor(currentlySelectedDoctorInformation)}>
                            Accept    
                        </Button>
                    </>
                }
            >
                <DoctorCard_CalendarInfo userData={currentlySelectedDoctorInformation}/>
            </Modal>

            {/* Clinic Information Modal - From Doctor Calendar */}
            <Modal
                title="Clinic Information"
                centered
                open={isClinicInformationModalOpen} 
                onOk={closeClinicInformationModal} 
                onCancel={closeClinicInformationModal} 
                footer={
                    <>
                        {/* <Button className="secondary-button button-size-default" onClick={closeClinicInformationModal}>
                            Back    
                        </Button> */}
                        <Button className="primary-button button-size-default" onClick={closeClinicInformationModal}>
                            Close    
                        </Button>
                    </>
                }
            >
                {/* <DoctorCard_CalendarInfo userData={currentlySelectedDoctorInformation}/> */}
                <ClinicInfoCard_DoctorCalendar clinicData={currentlySelectedClinicInformation}/>

                {/* TODO: Add clinic information here */}

            </Modal>

            {/* Confirm Delete Shift */}
            <GenericModal
                handleCancel={closeDeleteConfirmationModal}
                modalTitle={"Confirm Delete"}
                isGenericModalOpen={isDeleteConfirmationModalOpen} 
                bodyText={"Are you sure you want to delete this shift?"}
                showButton='true'
                showLoadingSpinner={false}
                showCancelButton='true'
                buttonFunction={deleteShift} 
                buttonText={"Confirm Delete"} 
                zIndex={1000}
            ></GenericModal>

            {/* Confirm Mark Shift as Scheduled Externally */}
            <GenericModal
                handleCancel={closeMarkShiftAsScheduledExternallyConfirmationModal}
                modalTitle={"Confirm Shift Scheduled Externally"}
                isGenericModalOpen={isMarkShiftAsScheduledExternallyConfirmationModalOpen}
                bodyText={"This is to confirm that you have filled this shift using other means, outside of OD Shift. Are you sure you want to mark this shift as scheduled externally?"}
                showButton='true'
                showLoadingSpinner={false}
                showCancelButton='true'
                buttonFunction={confirmScheduledExternally}
                buttonText={"Confirm Scheduled Externally"}
                zIndex={1000}
            ></GenericModal>
            
            {/* Confirm Accept Doctor */}
            <GenericModal
                handleCancel={closeAcceptDoctorModal}
                modalTitle={"Confirm Accept Doctor"}
                isGenericModalOpen={isOpenAcceptDoctorModal} 
                bodyText={"Are you sure you want to accept this doctor?"}
                showButton='true'
                showLoadingSpinner={false}
                showCancelButton='true'
                showButtonLoading={confirmAcceptDoctorButtonLoading}
                buttonFunction={acceptDoctor} 
                buttonText={"Confirm"} 
                zIndex={1100}
            ></GenericModal>

            {/* Edit Accepted Doctor within "Doctor Applications" Tab */}
            <GenericModal
                handleCancel={closeEditAcceptedDoctorModal}
                modalTitle={"Edit Accepted Doctor"}
                isGenericModalOpen={isEditAcceptedDoctorModalOpen}
                bodyText={"We don't yet have the ability to 'un-accept' a doctor from a shift. If you need to un-accept the doctor, please contact the doctor directly to let them know. This is on our roadmap to build very soon, thanks."}
                showButton='true'
                showLoadingSpinner={false}
                showCancelButton='false'
                // showButtonLoading={confirmAcceptDoctorButtonLoading}
                buttonFunction={closeEditAcceptedDoctorModal}
                buttonText={"Close"}
                zIndex={1100}
            ></GenericModal>

            {/* Confirm Doctor Cancel Shift - From Doctor Calendar */}
            <GenericModal
                handleCancel={closeConfirmDoctorCancelShiftModal}
                modalTitle={"Confirm Cancel Shift"}
                isGenericModalOpen={isConfirmDoctorCancelShiftModalOpen}
                bodyText={"We don't yet have the ability to 'cancel' a shift. If you need to cancel your shift, please contact the clinic directly to let them know. This is on our roadmap to build very soon, thanks."}
                showButton='true'
                showLoadingSpinner={false}
                showCancelButton='false'
                // showButtonLoading={confirmAcceptDoctorButtonLoading}
                buttonFunction={closeConfirmDoctorCancelShiftModal}
                buttonText={"Close"}
                zIndex={1100}
            ></GenericModal>
            

            {/* Dev Visualization Tool to visualize react-hook-form state */}
            {/* <DevTool control={control} /> */}
        </>

    );
}

export default CreateOrEditShiftModal


// Notes
// Controlled forms vs uncontrolled forms. its re-rendering
// controlled, is an input that is referencing a useState variable that is constantly rendering into it.
// useRef is allowing you to refernce the actual HTML, without value={}, to update the value that way, instead of directly calling the value={}
// read up on refs 

// Learn about context in React
// a parent component that holds state for all children, then dont need to manage props between many components.
// https://react.dev/learn/passing-data-deeply-with-context
// https://react.dev/learn/scaling-up-with-reducer-and-context
// Combining context and useReducer can be really powerful
// then you can right unit tests to check your state.