import React, { useState, useEffect, useRef, useCallback } from "react";
import { Auth } from "aws-amplify";
import { useNavigate, useLocation } from "react-router-dom";
import ErrorBoundary from "./components/ErrorBoundary";
import { AppContext } from "./lib/contextLib";
import { onError } from "./lib/errorLib";
import Routes from "./Routes";
import Logo from "./components/Logo";
import Footer from "./components/Footer";
import { API } from "aws-amplify";
import { GoogleMapsProvider } from './contexts/GoogleMapsContext';
import "./App.css";

// onboarding modal logic
import WelcomeModal from "./utilityComponents/WelcomeModal";
import JoinOrgModal from "./utilityComponents/JoinOrgModal";
import CreateClinicModal from "./utilityComponents/CreateClinicModal";
import CreateOrEditShiftModal from "./utilityComponents/CreateOrEditShiftModal";
import EditDoctorProfileModal from "./utilityComponents/EditDoctorProfileModal";
import NotificationContent from "./utilityComponents/NotificationContent";
import LeaveFeedbackModal from "./utilityComponents/LeaveFeedbackModal";
import SocialMediaButtons from "./utilityComponents/SocialMediaButtons";


// Generic Modal Utility
import GenericModal from "./utilityComponents/GenericModal";

import { MailOutlined, 
        TeamOutlined, 
        CalendarOutlined,
        UserOutlined,
        MenuOutlined,
        BellOutlined,
        LoginOutlined, 
        LogoutOutlined,
        SearchOutlined,
        LineChartOutlined,
        MessageOutlined,
        EditOutlined
       } from '@ant-design/icons';

import { Button, Layout, Menu, Drawer, Grid, Tag, Badge, Popover, Typography} from 'antd';
const { Text } = Typography;

  // ------------------------------ //
  // Rare and uncommon Bug (can't even recreate)
  // Find this rare bug and fix it.
  // In profile page, after editting a clinic, and saving, one of the other clinics adopts that editted clinics information. Saving the wrong info to the wrong clinic.
  // Create clinic, then open edit clinic for one clinic, close it without saving, then open edit clinic for the other clinic, and hit save with out changing anything.
  // This updates the clinic with the previous clinics information, only updates the slinic Name, website, (ans phone number? Notes? and Examp Types?)
  // Not able to recreate this bug on dev.odshift.com.. Need to keep looking to recreate this.. should be very rare, but does change data in dynamo to be incorrect. Could be difficult for users to catch themselves.
  // ------------------------------ //

  // NOT DONE WITH "REVIEW" BUTTON
  // the review button takes you to /calendar, and opens the right modal, but if you have mutltiple
  // clinics, it does not switch you to the correct clinic calendar as well.. NEED TO DO THIS ALSO..
  // Look at the buttons in the profile to see how to do that...
  // Need to hit openClinicCalendar() at line 416 in ClinicInformation_Profile.js..
  // How can we hit this from the ViewDoctor() in NotificationContent.js?.. abstract openClinicCalendar() into a utility function...
  // 


  // Send us an email also letting us know if there is a new interested doctor or accepted doctor..//
  // do this in the same Lambda function where we are already sending those emails... that should be super easy and fast..


  // DO THIS NEXT
  // Show if "Scheduled externally" on the calendar, then take away the button to scheduled externally

  // Get new stripe account for OD Shift and get new keys, and push new keys to Secrets Manager.

  // Build Airtable "OD Shift Analysis"
  // Create new Lambda function and event bridge rule to pull data and populate every 30 minutes.
  // https://airtable.com/appWfvNBrQOJw4JjQ/tblvej41ChikkbJEB/viw06TPjAP9hh8DlH?blocks=hide
  // first before event bridge just set up a lambda function and run it from an api call to go and 
  // pull all the data and populate the airtable...



  // CHECK TO MAKE SURE THAT THESE BUGS DO NOT EXIST //
  // POTENTIAL BUG // // I DO THINK IT IS BECAUSE OF SLOW INTERNET...
  // THE SLOW ROUND TRIP TIME WHEN REPULLING clinic and shift data.. everytime you close the modal
  // and select a new existing shift.. then the data returns and populates... looks like a bug but
  // just because of slow internet... Good one to keep and eye on...
  // and also why we have the slow internet warning.. because it breaks things.
  /// ALSO BUG IN DEV... MAYBE IN PROD... app.js line 1092
  // It seems that the first date you click gets set to "currentlySelectedShiftToEdit"..
  // or somehow into state..
  // then after that, whenever you click an existing shift, the corect date shows up for a bit,
  // but then switches back to the previous shift, when the API polling call is made..
  // Things to check, make sure that "currentlySelectedShiftToEdit" is set..


  // CreateOrEditShiftModal line 1453.. Potential bug..
  // currentlySelectedShiftToEdit not populating with calendar button click..?
  // the date seems to stay correct when clicking from notifications panel..
  // but when clicking from the calendar.. the date becomes incorrect.
  // Changes from the date you selected to another date...
  // IT SEEMS THAT IT IS PULLING UP THE LAST CALENDAR DATE THAT YOU CHOSE, LIKE ONE BEHIND...
  // was this happening previously?? check dev... looks to be working right on dev...
  // hmmm seems that the currentlySelectedShiftToEdit is wrong?.. this could be because of the slow internet??
  // but working on dev... hmm. look at the console logs


  ////////////////////////////////////
  // Add Airtable "live copy" of userData, clinicData, and shiftData
  // build this logic

  ////////////////////////////////////
  //// Add Automated Email Alerts ////
  // Need to create an EventBridge Schedule:
      // After a doctor has applied to the shift, (it will automatically send an email to let the clinic know), create schedule for
      // 3 days after the date that the doctor has applied to remind the clinic they have a doctor who applied, if the shift status is still "Available"
      // and then send another reminder 3 days after that, and 3 days after that, until either the shift is marked as "Scheduled", or the shiftDate passes.
      // only send the reminders if the shift date has not already passed.

  // Need to create an EventBridge Schedule:
      // Send automatic emails to doctors, letting them know how many open shifts are available on the platform
      // send these weekly.. check if the user has "unsubscribed" or not. Need to keep track of this in dynamoDB
      // need to add a mechanism to allow the user to unsubscribe, button in email, take them to a new /unsubscribe route
      // allow them to input their email address and need new lambda function for this.. unauthenticated api call? similar to waitList logic?

  // Need to create an EventBridge Schedule:
      // Send automatic email to doctor one week and then 2 days before their "Scheduled" shift to remind them that they have been accepted to the shift.
      // Remind them to speak with the clinic if they have not yet.

  // Need to create an EventBridge Schedule:
    // Send automatic email to doctor
    // "we realized that you didn't complete your profile. Doctors who complete their profile can search and apply for shifts."

  ////////////////////////////////////


  // preferences for email notifications for shift alerts in profile page..
  // Add links to Facebook page, instagram page and LinkedIn page into the html of the automated emails.

  // Send Emails to Users. Get UX Feedback: //
  // Send email to doctors that have applied to shifts, see how we can improve //
  // Send email to orgs that have multiple clinics, get feedback on that process //
  // Send email to all doctors every week? let them opt out of the weekly emails? //
  // Send email to all doctors, ask how we can improve the app, or seen any bugs. //
  // Send email to all clinics, ask how we can improve the app, or seen any bugs. //
  // Send email to specific users asking for feedback and asking them how we could make it more valuable for them //


  // Marketing //
  // Look at notes from Levin //
  // Talk to OD Influencers to talk about OD Shift //
  // Keep moving forward with targeted advertising //
  // Create still content for ads, get inspo and design in figma //


  // Stripe Integration: //
  // Create an ODShift bank account
  // Create new stripe account for ODShift.. connect to ODShift Bank Account.
  // Look at Settings.js at line 77. This is where to start testing...
  // Test the intial implementation and then expand to create full billing page.
  // Use chatgpt to create the react code for the billing page new expanded layout.
  // Payments Page Layout: https://www.saasframe.io/categories/upgrading#:~:text=Upgrade%20to%20SaaSFrame%20Pro%20now%20to%20see%2054
  // Read up on Stripe Secret API Key vs Publishable API key

  // Targeted Marketing: //
  // (https://www.udemy.com/course/facebook-ads-masterclass-2023-all-campaigns-features/learn/lecture/40922208?start=0#overview)


  /////// High Priority: ///////
  // Email validation on clinic creation and clinic editting.. (logic is already set correctly on account creation. Grab from there)
      // This should be in Signup.js.. need to add it to CreateClinicModal.js (line 148) and ClinicInformation_Profile.js (line 207)
      // Find the UI locations for these input fields and make sure the validation is set correctly..
  // Add dropdown in signup page.. how did you hear about OD Shift?

  //
  // Clear all the random console logs...
  //

  // Find UI location to tell clinics they will recieve an email when a doctor has applied for their shift.
  // and also UI to tell doctors they will recieve an email when they have been scheduled for a shift.
  // Re-order notification list to have most recent item at the top. (in lambda, or frontend? probably lambda... test this..) getAllclinicDataAndShiftData.js
  // Refactor accept doctor table and logic sequence
  // Manual Refresh buttons
  // On mobile scroll up on confirmation code page..
  // Allow clinic user to delete a clinic

  // ------
  // ------

  /////// Mid Priority: ///////
  //   -  Setup an Admin Page for Mal and I, only on dev.
  //   -  When the date range picker in open shift list page is open, stop polling api.
  //   -  Take out Doctor Bio from profile
  //   -  Dont let a clinic create a new shift on a date in the past.
  //   -  "If you want to delete your profile, please reach out to us"
  //   -  set onboarding complete for clinicTeamMembers after they create a clinic, or if they join an existing team...
  //   -  Invite a clinic or doctor
  //   -  Work on filtering flow - no full page refresh //
  //   -  Write Unit Tests
  //   -  Update logic to show the accept and edit buttons - in clinic calendar after doctor applies.. in "Doctor Applications" tab
          // createOrEditShiftModal.js line 844
          // Need better logic for this... refactor... get rid of lag when clicking accept..
          // need that loading so no slow updates.. need generally better logic for this...
  //   -  build analytics to find power users
  //   -  "Go to Calendar" Button from clinic profile clinic cards..
  //   -  do we need to take out www. when user input websites?
  //   -  Finish clinic 'un-accept' and doctor 'cancel shift' logic
  //   -  After Create Clinic, tell user "go to your clinic calendar to add shifts"
  //   -  Additional input validation.. email and phone number are formatted correctly, etc

  /////// Low Priority: ///////
  //   -  Check if Doctor has applied to two shifts on the same day... open modal to tell them to cancel other shifts.
  //   -  Check when new clinicTeamMember creates an account if any other colleagues of theirs has already joined and created an org.
          // Automatically join them to the org.
  //   -  Add logic to mark a notification as "read", new key value in acceptedDoctor and interestedDoctor Objects in dynamoDB..
          // use this first hard coded filter, then expose additional filtering down stream.
  //   -  Add "Add Clinic" Button in clinic calendar
  //   -  Edit Marketing Page, add extra buttons and modals
  //   -  Go through and take out majority of console logs. even on button clicks, ie. create new shift.
  //   -  Clean up.. Take out notes logic... Take out Lambda functions that we are not using.
  //   -  Add dynamodb table config for IsLambdaLoggingOn. Then have api call first thing in all Lambda functions. Should be negligable latency.
          // Then have ability to externally toggle on and off logging within Lambda functions without needing to re-deploy infra.
  //   -  in Clinic Information Modal in Doctor calendar, click on address, to open map in google maps in new tab.
  //   -  Delete Clinic logic
        // Delete all associated shifts... need new API call.
  //   -  Add Google Analytics (2 hours)
  //   -  clinic creation check... when a company creates a clinic we can check and see if there is another clinic created with that same
          // clinic email address, tell them if there is already a clinic that exists for their team.
  //   -  REFACTOR and pull out utilities.. IMPORTANT
  //   -  Handle Doctor Shift "Scheduled Elsewhere":
          // May need to also add an AcceptedShifts Key in dynamoDB for doctors... already have interestedShifts in there.
          // We could then use this to keep track if a doctor applies to multiple shifts on the same day and then gets "scheduled" for a one,
          // On the doctor applicants lists of the shifts he did not apply for, it should show "Scheduled Elsewhere" or something..
          // But then need to manage when canceled or that changes, need to change it in BOTH the shiftData AND userData locations...
  //   -  Send email to doctor when scheduled shift is upcoming in 24 hours from now.. Need eventBridge scheduled event?
  //   -  Organize Notification lists to show most recent at the top.
  //   -  .ics creation and download to import calendar events to outlook or google calendar
  //   -  link to zocdoc?? do opto have zocdoc

  // BUG: google input field sets incorrectly if no internet.. then when you get internet back on, it doesn't get fixed until a age refresh....
  // force page refresh after losing and getting back internet connection.

  /////// Backlog Items: ///////
  //   -  if individual doctor has opted into getting notifications/emails when new shifts are posted in their area, they get a notification/email.
  //   -  add logic around shifts in the past, or "completed" shifts.
  //   -  add information circle i button to join org and create org buttons and all over the place. make sure to explain very clearly what is going on.
  //   -  Add more info pop overs to explain things in onboarding forms/modals.
  //   -  Add Google sso auth?
  //   -  Figure out invite colleagues logic/flow
  //   -  Add corner button - https://ant.design/components/float-button#:~:text=Open%20menu%20mode%20with%20trigger%2C%20which%20could%20be%20hover%20or%20click.
  //   -  Improve input validation
  //   -  After Log out clear all userData and other data. This will stop recurring API calls after logout.

  // ------------------------------ //
  // ------------------------------ //

function App() {
  const nav = useNavigate();
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);

  // Data from DynamoDB Tables
  const [userData, setUserData] = useState({});
  const userDataRef = useRef(null); // Create a ref for stable reference
  const [orgData, setOrgData] = useState({});
  const [clinicData, setClinicData] = useState([]);
  const [allDataForDoctorFindShiftsPage, setAllDataForDoctorFindShiftsPage] = useState({});

  // Notification system
  const [shiftsWithInterestedDoctors, setShiftsWithInterestedDoctors] = useState({}); // For clinicTeamMembers
  const [interestedShifts, setInterestedShifts] = useState({}); // For individualDoctors
  const [notificationObject, setNotificationObject] = useState([]);
  const [notificationPopoverVisible, setNotificationPopoverVisible] = useState(false);
  const [isMobileNotificationsModalOpen, setisMobileNotificationsModalOpen] = useState(false);

  // Onboarding Modal Logic
  const [isWelcomeModalOpen, setIsWelcomModalOpen] = useState(false); // onboarding modal
  const [isJoinOrgModalOpen, setIsJoinOrgModalOpen] = useState(false); // onboarding modal
  const [isCreateClinicModalOpen, setIsCreateClinicModalOpen] = useState(false); // onboarding modal
  const [createOrgSequence, setCreateOrgSequence] = useState(false); // if false, defaults to JoinOrgSequence.

  // Finish Docotor Onboarding
  const [isEditDoctorProfileInformationModalOpen, SetIsEditDoctorProfileInformationModalOpen] = useState(false);

  // Leave Feedback
  const [isLeaveFeedbackModalOpen, setIsLeaveFeedbackModalOpen] = useState(false);
  
  // Calendar Helper
  const [isCreateOrEditShiftModalOpen, setIsCreateOrEditShiftModalOpen] = useState(false);
  const [currentlySelectedShiftToEdit, setSelectedShiftToEdit] = useState({});
  const [selectedCalendarDate, setSelectedCalendarDate] = useState("");
  const [doctorFormattedInterestedShiftDataForCalendar, setDoctorFormattedInterestedShiftDataForCalendar] = useState({});
  const [currentlySelectedClinicCalendar, setCurrentlySelectedClinicCalendar] = useState();
  const [clinicCalendarList, setClinicCalendarList] = useState([]); // Calendar Clinic List

  const [initialPageLoaded, setInitialPageLoaded] = useState(false);

  // For Generic Modal Utility
  const [isGenericModalOpen, setIsGenericModalOpen] = useState(false); // generic modal - used in add clinic process.
  const [genericModalTitle, setGenericModalTitle] = useState(""); 
  const [genericModalBodyText, setGenericModalBodyText] = useState("");
  const [genericModalButtonFunction, setGenericModalButtonFunction] = useState(() => {return () => console.log("Default action executed"); });
  const [genericModalButtonText, setGenericModalButtonText] = useState("");
  const [showGenericModalShowSpinner, setShowGenericModalShowSpinner] = useState("false");

  // UI Layout Helpers 
  const [visibleDrawer, setVisibleDrawer] = useState(false); // menu drawer
  const { Header, Content, Sider } = Layout;
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const isMobile = !screens.md; 
  const [loadingData, setLoadingData] = useState(false);
  const [currentPage, setCurrentPage] = useState("");

  // Checking for Inactivity for API polling
  const [isUserActive, setIsUserActive] = useState(true);
  const [isPageVisible, setIsPageVisible] = useState(true);
  const pollingInterval = useRef(null);
  const inactivityTimeout = useRef(null);
  const INACTIVITY_TIME = 180000; // If inactive for 3 minutes, stop API Polling

  // Check internet speed
  const [speed, setSpeed] = useState(null);

  // const { Item: MenuItem } = Menu;
  const [selectedKey, setSelectedKey] = useState('sub1');

  ////////////////////
  // Rest API Polling Interval -- TODO: Have different in dev vs prod
  // const [restApiPollingInterval, setRestApiPollingInterval] = useState( 60000); // 30 seconds..
  const [restApiPollingInterval, setRestApiPollingInterval] = useState(() => {
    const hostname = window.location.hostname;
    console.log("hostname: ", hostname);
    if (hostname.includes("dev") || hostname.includes("localhost")) {
      // dev
      return 10000; // 10 seconds
    } else {
      // prod
      return 60000; // 60 seconds
    }
  });
  ////////////////////

  useEffect(() => {
    onLoad();
    // console.log("useEffect working from App.js");
  }, []);

  const location = useLocation();
  // Update the current page whenever the route changes
  useEffect(() => {
    let currentPage_useLocation = location.pathname;
    if(currentPage_useLocation.startsWith('/')){
      currentPage_useLocation = currentPage_useLocation.substring(1).trim();
    }

    setCurrentPage(currentPage_useLocation); // Use location.pathname to set the current route
    // console.log("currentPage_useLocation react hook: ", currentPage_useLocation);
  }, [location]);

  // Using this to clear session Storage for calendar 
  // Reset sessionStorage value when the page reloads // enable rest api polling
  useEffect(() => {
    const handleBeforeUnload = () => {
      // Clear the flag when the page is about to be refreshed
      sessionStorage.removeItem("initialCalendarLoaded");
      sessionStorage.removeItem("currentlySelectedClinicCalendar");
      sessionStorage.removeItem("currentlySelectedClinicCalendarName");
      sessionStorage.removeItem("currentlyEdittingInformation");
    };

    // Add the event listener for beforeunload
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // check internet speed
  useEffect(() => {
    const checkInternetSpeed = () => {
      if ("connection" in navigator) {
        const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
        const estimatedSpeedMbps = connection.downlink; // Speed in Mbps
        console.log("Estimated Internet Speed:", estimatedSpeedMbps, "Mbps");

        // Tell user if their internet connection is too slow..
        if (estimatedSpeedMbps < 1.5){
          console.log("It seems that your internet connection is not strong enough. Please try again later.");
          alert("It seems that your internet connection is not strong enough. Please try again later.");
        }

        setSpeed(estimatedSpeedMbps);
      } else {
        console.log("Network Information API is not supported on this browser.");
        setSpeed("Network Information API is not supported.");
      }
    };

    checkInternetSpeed();
  }, []);

  //////// Checking for User Innactivity to stop rest API polling
  ///////////////////////////////////////////////////////////////////////////////////////////////
  ////////

  // Handle visibility changes
  useEffect(() => {
    const handleVisibilityChange = () => {
      const isVisible = document.visibilityState === "visible";
      console.log("document.visibilityState: ", document.visibilityState);
      setIsPageVisible(isVisible);
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  // Handle user inactivity
  useEffect(() => {
    const resetInactivityTimer = () => {
      // console.log("user active on page - resetting inactivity timer");
      setIsUserActive(true);

      if (inactivityTimeout.current) {
        clearTimeout(inactivityTimeout.current);
      }

      inactivityTimeout.current = setTimeout(() => {
        setIsUserActive(false);
      }, INACTIVITY_TIME);
    };

    const activityEvents = ["mousemove", "mousedown", "keypress", "touchstart", "scroll"];
    activityEvents.forEach((event) =>
      document.addEventListener(event, resetInactivityTimer)
    );

    // Initialize timer on mount
    resetInactivityTimer();

    return () => {
      activityEvents.forEach((event) =>
        document.removeEventListener(event, resetInactivityTimer)
      );

      if (inactivityTimeout.current) {
        clearTimeout(inactivityTimeout.current);
      }
    };
  }, []);

  // Start/Stop polling based on visibility and user activity
  useEffect(() => {

    const initialClinicSideApiCallBeforePollingStart =  async () => {
      await getClinicDataAndCheckShiftsForInterestedDoctors(userDataRef.current);
    }

    const initialDoctorSideApiCallBeforePollingStart =  async () => {
      await getALLClinicDataAndCheckShiftsForInterestedShifts(userDataRef.current);
    }

    console.log("isUserActive on page: ", isUserActive);
    if (isPageVisible && isUserActive) {
      // console.log("userData?.userType: ", userData?.userType);
      if(userData?.userType === 'clinicTeamMember'){
        console.log("startPolling_ClinicSide");
        initialClinicSideApiCallBeforePollingStart();
        startPolling_ClinicSide();
      } else if (userData?.userType === 'individualDoctor'){
        console.log("startPolling_DoctorSide");
        initialDoctorSideApiCallBeforePollingStart();
        startPolling_DoctorSide();
      }else{
        console.log("userData is undefined");
      }

    } else {
      stopPolling();
    }

    return () => stopPolling(); // Cleanup on component unmount
  }, [isPageVisible, isUserActive]);


  //////// Core Logic
  ///////////////////////////////////////////////////////////////////////////////////////////////
  ////////

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
      await onLoginEvent();

    } catch (e) {
      if (e !== "No current user") {
        onError(e);
      }
    }

    setIsAuthenticating(false);
  }

  async function onLoginEvent(doNotShowLoading_JustRePull) {

    // Reminder:
    // After Sign Up in SignUp.js, it waits for 1 second before triggering this to make sure that when we pull the user data, it is populated.

    // In certain circumstances we want to re-pull data without having the loading screen pop up
    // console.log("doNotShowLoading_JustRePull: ", doNotShowLoading_JustRePull);
    if (doNotShowLoading_JustRePull === undefined){
      setLoadingData(true);
      await delay(300);
    }

    // API Call to get userData
    const userDataReponse = await getUserData();
    const userDataParsed = await JSON.parse(userDataReponse.body);
    // await setUserDataParsed_ForRestApiPolling(userDataParsed);
    console.log("userData: ", userDataParsed.userData);
    setUserData(userDataParsed.userData);
    const user = await Auth.currentAuthenticatedUser();
    // console.log("user: ", user);

    // confirm user is authenticated
    if(user !== undefined && userDataParsed.userData !== undefined){
      // Curate onboarding Sequence per user.
      // Conditwionally turn on correct onboarding modal based on current user onboarding progress.
      if(userDataParsed.userData.userType === 'clinicTeamMember'){
        // user has either joined or created an org.
        if(userDataParsed.userData.orgId != undefined){
          // check if their org has any associated clinics.
          // need api call here to check the org database.
          const orgParams = {
              orgId: userDataParsed.userData.orgId, 
          }
          const orgDataResult = await getOrgData(orgParams);
          console.log("orgData: ", orgDataResult.orgData);
          setOrgData(orgDataResult.orgData);

          // TODO:
          // If their org does have associated clinics, set this user's data to onboardingComplete=True
          // Need to make API call to set this.

          // Now get Clinic Data and Shift Data
          if(orgDataResult?.orgData?.clinics != undefined){

            await getClinicDataAndCheckShiftsForInterestedDoctors(userDataParsed);
            setLoadingData(false);

            // START POLLING REST API //
            await startPolling_ClinicSide(userDataParsed);

            // Set selected state for clinic calendar buttons list. (top left) - make sure to only do this on initial page load
            // Drilling down through the DOM to select the clinic menu item and show it as selected
            // Only do this on intial page load
            // console.log("initialPageLoaded: ", initialPageLoaded);
            // REFACTOR.. don't use DOM manipulation
            if(!initialPageLoaded){
              setTimeout(() => {
                const antMenu = document.querySelector('.menu-clinics');
                if (antMenu) {
                  const clinicMenuList = antMenu.querySelector('.ant-menu-submenu');
                  const clinicList = clinicMenuList?.querySelector('.ant-menu');
                  const firstClinicItem = clinicList?.querySelector('.ant-menu-item');
                  // console.log('firstClinicItem - this should only hit on first load...: ', firstClinicItem);
                  // console.log('setting selected state of first clinic menu items... should only happen on first load..');
                  if (firstClinicItem) {
                    firstClinicItem.classList.add('custom-selected');
                    firstClinicItem.classList.add('ant-menu-item-selected');
                    const menuTitle = firstClinicItem?.querySelector('.ant-menu-title-content');
                    // console.log("menuTitle: ", menuTitle);
                    const antBadge = menuTitle?.querySelector('.ant-badge');
                    // console.log("antBadge: ", antBadge);
                    antBadge?.classList.add('custom-selected');

                  }
                }
              }, 100); // Wait for 100ms before querying
              setInitialPageLoaded(true);
            }

          }else{
            // If you are part of an org, but have no associated clinics
            OpenModal_Generic(
              "Add a Clinic",
              "It looks like your Organization does not have any associated clinics yet. Add one or more clinics to start posting open shifts.",
              OpenModal_CreateAClinic,
              "Add Clinic",
              false
            );

            setLoadingData(false);
          }

        }else{
          setLoadingData(false);
          OpenModal_InitialOnboard();
        }
      }else{
        // individual doctor 

        // Onboarding modal logic 
        if(userDataParsed.userData != undefined && userDataParsed.userData.onboardingComplete){
          console.log("user has completed onboarding.");
          await getALLClinicDataAndCheckShiftsForInterestedShifts(userDataParsed);

          // Dont show loading in the doctor or clinic until they actually have data saved.
          // We don't want to flash the loading 
          setLoadingData(false);

          // START POLLING REST API //
          await startPolling_DoctorSide(userDataParsed);

        }else{
          setLoadingData(false);
          OpenModal_InitialOnboard();
        }

      }
    }else{
      console.log("not authentcated or userData is undefined.");
    }

  };

  // START POLLING - Doctor Side //
  const startPolling_DoctorSide = async (userDataParsed) => {
    if (userDataParsed) {
      userDataRef.current = userDataParsed; // Always reassign on start
    }

    if (!pollingInterval.current) {
      pollingInterval.current = setInterval(async () => {
        if (userDataRef.current) {
          await getALLClinicDataAndCheckShiftsForInterestedShifts(userDataRef.current);
        } else {
          console.warn("userDataRef.current is undefined inside interval.");
        }
      }, restApiPollingInterval);
    }
  };

  // START POLLING - Clinic Side //
  const startPolling_ClinicSide = async (userDataParsed) => {
    if (userDataParsed) {
      userDataRef.current = userDataParsed; // Always reassign on start
    }

    if (!pollingInterval.current) {
      pollingInterval.current = setInterval(async () => {
        if (userDataRef.current) {
          await getClinicDataAndCheckShiftsForInterestedDoctors(userDataRef.current);
        } else {
          console.warn("userDataRef.current is undefined inside interval.");
        }
      }, restApiPollingInterval);
    }
  };

  // STOP POLLING - Both sides //
  const stopPolling = () => {
    console.log("Stop Polling the API... either user has been innactive for 3 minutes, or left the browser tab.");
    if (pollingInterval.current) {
      clearInterval(pollingInterval.current);
      pollingInterval.current = null;
    }
  };

  // NOTIFICATIONS //
  const setNotificationPopoverVisibleTrigger = () => {
    if(notificationObject?.length > 0){
      setNotificationPopoverVisible(!notificationPopoverVisible);
    }
  }

  // dont need to pass in userId, lambda gets user id from 
  // event.requestContext.authorizer.iam.cognitoIdentity.identityId
  function getUserData() {
    try {
      return API.post("od-api", "/getUserData", {
        body: {userId: ""},
      }).then((data) => {

        console.log("get user data response: ", data);
        // do things
        return data;

      });
    } catch (e) {
      onError(e);
      console.log("ERROR: ", e);
    }
  }

  //////////////
  //////////////
  // REST API POLLING // For clinic side calendars
  //////////////
  //////////////

  // Only getting this specific Organization's clinic and shift data.
  async function getClinicDataAndCheckShiftsForInterestedDoctors(userDataParsed){

    // Check if all modals are closed.. if so, not editting information
    // Set currentlyEdittingInformation(false);
    const isModalOpen = () => {
      // Look for the '.ant-modal-mask' element which appears when a modal is open
      const modalOverlay = document.querySelector('.ant-modal-mask');
    
      // If the overlay is present, assume the modal is open
      return modalOverlay !== null;
    };

    if (isModalOpen()) {
      // console.log('The modal is currently open!');
    } else {
      // console.log('The modal is closed.');
      sessionStorage.setItem("currentlyEdittingInformation", false); // enable rest api polling
    }

    // Only re-pull data if not currently editting information, ie shift data, proflie data, clinic data, etc.
    // So that we don't want to "re-pull and clear", use sessionStorage to hold state data on this...
    const currentlyEdittingInformation = sessionStorage.getItem("currentlyEdittingInformation");
    // console.log("currentlyEdittingInformation: ", currentlyEdittingInformation);

    if(currentlyEdittingInformation === null || currentlyEdittingInformation === 'false'){
      console.log(`POLLING API - CLINIC SIDE - Interval: ${restApiPollingInterval} milliseconds`);

      const clinicParams = {
        orgId: userDataParsed?.userData?.orgId,
      };
      const clinicData = await getClinicAndShiftData(clinicParams);
      
      if(clinicData !== undefined){
  
        console.log("clinicData: ", clinicData);
        setClinicData(clinicData?.clinicData);
        
        // ONLY DO THIS ON FIRST PAGE LOAD... WE DONT WANT TO ALWAYS DEFAULT TO THE FIRST CALENDAR ON EACH PAGE LOAD...
        // if no currenty selected clinic, use default first clinic
        // Trying to fix defaulting back to first calendar every time we recieve new data.. conditionally pass which new calendar data to show based on which calendar is selected.
        // persist accross component re-renders.. using sessionStorage
        const savedCalendarLoaded = sessionStorage.getItem("initialCalendarLoaded");
        // console.log("savedCalendarLoaded: ", savedCalendarLoaded);
  
        // console.log("initialCalendarLoaded: ", initialCalendarLoaded);
        if(currentlySelectedClinicCalendar === undefined && savedCalendarLoaded === null){
        // if(currentlySelectedClinicCalendar === undefined){
          setCurrentlySelectedClinicCalendar(clinicData?.clinicData[0]); // default first clinic to inital selection
          sessionStorage.setItem("currentlySelectedClinicCalendar", clinicData?.clinicData[0]?.clinicId); // save which calendar you are currently on to sessionStorage..
          sessionStorage.setItem("currentlySelectedClinicCalendarName", clinicData?.clinicData[0]?.clinicName);
          // console.log("default currectlySelectedClinicCalendar: ", clinicData.clinicData[0]);
          sessionStorage.setItem("initialCalendarLoaded", true);
        }else{
          // find out which clinicCalendar you were on previously and populate that one on new data load..
          const currentlySelectedClinicCalendarFromSessionStorage = sessionStorage.getItem("currentlySelectedClinicCalendar");
          // console.log("currentlySelectedClinicCalendarFromSessionStorage: ", currentlySelectedClinicCalendarFromSessionStorage);
  
          // console.log("clinicData.clinicData.length: ", clinicData.clinicData.length);
          for(var i=0; i< clinicData?.clinicData.length; i++){
            // console.log("checking which calendar is currently selected: ", clinicData.clinicData[i]?.clinicId + " vs. " + currentlySelectedClinicCalendarFromSessionStorage);
            if(clinicData.clinicData[i]?.clinicId === currentlySelectedClinicCalendarFromSessionStorage){
              // console.log("should reset calendar data with new data, ", clinicData.clinicData[i]);
              setCurrentlySelectedClinicCalendar(clinicData.clinicData[i]);
            }
          }
          
        }
    
        // Here check for "interestedDoctors" and populate a new state object.
        // console.log("Now check the shifts for interestedDoctors, and send notification in frontend.");
        let shiftsWithInterestedDoctors_ShiftIdList = []; // use this to check against to make sure there are not duplicates.
        let shiftsWithInterestedDoctors_ObjectList = [];
    
        
        for(var i=0; i<clinicData.clinicData.length; i++){
    
          // loop through and grab the shifts that doctors have applied to.
          if(clinicData?.clinicData[i].shiftData != undefined){
            for(var j=0; j<clinicData.clinicData[i].shiftData.length; j++){
              if(clinicData.clinicData[i].shiftData[j].interestedDoctors != undefined){
                for(var k=0; k<clinicData.clinicData[i].shiftData[j].interestedDoctors.length; k++){
                  if(!shiftsWithInterestedDoctors_ShiftIdList.includes(clinicData.clinicData[i].shiftData[j].shiftId)){
                    shiftsWithInterestedDoctors_ShiftIdList.push(clinicData.clinicData[i].shiftData[j].shiftId);
                    shiftsWithInterestedDoctors_ObjectList.push(clinicData.clinicData[i].shiftData[j]);
                    // console.log("Shift containing an interestedDoctor: ", clinicData.clinicData[i].shiftData[j]);
                  }
                };
              }
            };
          }
    
          // check clinics and find the currently selected clinic calendar to reset the data.
        };
    
        setShiftsWithInterestedDoctors(shiftsWithInterestedDoctors_ObjectList);

        // console.log("shiftsWithInterestedDoctors_ShiftIdList: ", shiftsWithInterestedDoctors_ShiftIdList);
        // console.log("FOR NOTIFICATION SYSTEM - CLINIC SIDE - Shifts With Interested Doctors - check if doctors have applied to your open shift: ", shiftsWithInterestedDoctors_ObjectList);

        ////////////////////////
        // NOTIFICATION LOGIC - For Clinic //
        ////////////////////////
        reformatClinicShiftObjectsForNotificationPanel(shiftsWithInterestedDoctors_ObjectList, userDataParsed);

        // Need to check which clinics have shifts that have interestedDoctors, but are not "Scheduled".
        const clinicsWithShiftsWithInterestedDoctorsButNotScheduled = [];
        // create clinicsWithShiftsWithInterestedDoctorsButNotScheduled array
        for(var i=0; i<clinicData?.clinicData.length; i++) {
          for (var j=0; j<clinicData?.clinicData[i]?.shiftData?.length; j++){
            if(clinicData?.clinicData[i]?.shiftData[j]?.acceptedDoctor === undefined
              && clinicData?.clinicData[i]?.shiftData[j]?.interestedDoctors?.length > 0
            ){
              // this shift has interestedDoctors but none have been accepted yet, show notification.
              // add to list clinicsWithShiftsWithInterestedDoctorsButNotScheduled
              clinicsWithShiftsWithInterestedDoctorsButNotScheduled.push(clinicData?.clinicData[i].clinicName);
              // clinicData?.clinicData[i].shiftData[j].hasInterestedDoctorsButNotScheduled = true;
            }
          }
        }
        // console.log("clinicsWithShiftsWithInterestedDoctorsButNotScheduled: ", clinicsWithShiftsWithInterestedDoctorsButNotScheduled);

        // Set list of calendars in the left main menu.
        const opticalItemsFormat = clinicData?.clinicData.map((clinic, index) =>
          getItem(
            clinicsWithShiftsWithInterestedDoctorsButNotScheduled.includes(clinic.clinicName) ? 
              <Badge color="#F759AB"  dot  offset={[16, 16]}>
                {clinic.clinicName} 
              </Badge>
            : 
            clinic.clinicName
            , 
            clinic.clinicId)
        );
        setClinicCalendarList(opticalItemsFormat);
  
      }

      return clinicData;

    }else{
      console.log("not re-pulling data because currently editting information.")
    }

  }

  //////////////
  //////////////
  // REST API POLLING // For Doctor Open Shift List and calendar
  //////////////
  //////////////

  // Get list of "InterestedShifts" to create ui panel, also get notifications of any new shifts in your area.
  async function getALLClinicDataAndCheckShiftsForInterestedShifts(userDataParsed){
    console.log(`POLLING API - DOCTOR SIDE - Interval: ${restApiPollingInterval} milliseconds`);
    // console.log("userDataParsed: ", userDataParsed);

    const clinicParams = {
      lat: userDataParsed.userData.lat,
      lng: userDataParsed.userData.lng
    };
    const AllData = await getALLClinicAndShiftData(clinicParams);

    // Check to see if identical to last pull before setting the data into state and refreshing the app
    // ONLY setAllDataForDoctorFindShiftsPage THIS IF IT HAS CHANGED SINCE THE LAST RE_PULL
    console.log("AllData: ", AllData);
    setAllDataForDoctorFindShiftsPage(AllData);

    // Here check for "interestedShifts" and populate a new state object.
    // console.log("Now check the shifts for interestedShifts, and set up object to view in frontend.");
    let InterestedShifts_ShiftIdList = []; // use this to check against to make sure there are not duplicates.
    let InterestedShifts_ObjectList = [];
    // console.log("userDataParsed.userData.userId: ", userDataParsed.userData.userId);

    // loop through and grab the shifts that the doctor has applied to.
    for(var i=0; i<AllData.shiftData.length; i++){
      if(AllData.shiftData[i].interestedDoctors != undefined){
        for(var j=0; j<AllData.shiftData[i].interestedDoctors.length; j++){
          // console.log("AllData.shiftData[i].interestedDoctors[j]: ", AllData.shiftData[i].interestedDoctors[j]);
          // only grab shifts of which you are one of the interested doctors.
          if(AllData.shiftData[i].interestedDoctors[j].doctorId == userDataParsed.userData.userId){ 
            if(!InterestedShifts_ShiftIdList.includes(AllData.shiftData[i].shiftId)){
              InterestedShifts_ShiftIdList.push(AllData.shiftData[i].shiftId);
              InterestedShifts_ObjectList.push(AllData.shiftData[i]);
            }
          }
        };

      }
    };

    // console.log("shiftsWithInterestedDoctors_ShiftIdList: ", shiftsWithInterestedDoctors_ShiftIdList);
    // console.log("FOR NOTIFICATION SYSTEM - DOCTOR SIDE - Interested Shifts - check if you have been accepted or someone else has been: ", InterestedShifts_ObjectList);
    setInterestedShifts(InterestedShifts_ObjectList);

    if(InterestedShifts_ObjectList != undefined){
      // Change "SCHEDULED" to "NOT ACCEPTED" if another doctor was accepted..

      // Format the shift data for the calendar, and add new key
      const formattedInterestedShiftData = InterestedShifts_ObjectList.map(event => ({
        title: event.shiftId,
        date: event.shiftDate,
        className: 'custom-event',
        extendedProps: {
          status: checkIfDoctorWasAcceptedOrNot(event, userDataParsed), // Function to check if "NOT ACCEPTED"
          doctor: event.clinicData.clinicName, // using clinic address as second row for doctor modal
          shiftData: event // return the entire shift object in the extendedProps, to access in the calendar.
        }
      }));

      // Need to show "SCHEDULED" on calendar of accepted doctor and "NOT ACCEPTED" on calendar of doctors who are not accepted.
      function checkIfDoctorWasAcceptedOrNot(event, userDataParsed){

        let status = "";
        if(event.shiftState.toUpperCase() === "SCHEDULED"){
          if(event.acceptedDoctor?.doctorId === userDataParsed.userData.userId){
            status = event.shiftState.toUpperCase()
          }else{
            status = "NOT ACCEPTED"
          }
        }else{
          status = event.shiftState.toUpperCase()
        }
        return status
      }

      // console.log("formattedInterestedShiftData: ", formattedInterestedShiftData);
      setDoctorFormattedInterestedShiftDataForCalendar(formattedInterestedShiftData); // adding this data directly into userData to access from within "My Calendar"

      ////////////////////////
      // NOTIFICATION LOGIC - For Doctors //
      ////////////////////////
      reformatDoctorShiftObjectsForNotificationPanel(InterestedShifts_ObjectList, userDataParsed);

    }
  }

  // NOTIFICATIONS SHOWN TO DOCTORS //
  function reformatDoctorShiftObjectsForNotificationPanel(InterestedShifts_ObjectList, userDataParsed){

    // TODO: Only take the most current 20 timestamped events in the list.
    // Doctor side wants the 20 most recent "clinic accepted" notification events.
    // Clinic side wants the 20 most recent "doctor applied" notification events.
    // also need to re-order them to have most current at the top.
    // Create Array of shifts that have accepted this doctor..
    let shiftsThatHaveAcceptedThisDoctor = [];
    for(var i=0; i<InterestedShifts_ObjectList.length; i++){
      if(InterestedShifts_ObjectList[i].acceptedDoctor !== undefined){
        // console.log("found shift that a has an accepted doctor, add this shift to the notification array: ", InterestedShifts_ObjectList[i]);
        shiftsThatHaveAcceptedThisDoctor.push(InterestedShifts_ObjectList[i]);
      }
    }

    // console.log("shiftsThatHaveAcceptedThisDoctor: ", shiftsThatHaveAcceptedThisDoctor);
    if(shiftsThatHaveAcceptedThisDoctor.length > 0){
      const formattedShiftDataForNotifications = shiftsThatHaveAcceptedThisDoctor

        // TODO: Figure out how we want these sorted..
        // .sort((a, b) => {
        //   // Convert dates to timestamps for comparison
        //   const dateA = new Date(a.shiftDate);
        //   const dateB = new Date(b.shiftDate);
        //   // Sort descending (most recent first)
        //   return dateB - dateA;
        // })
        .map((event, index) => ({
          id: index + 1,
          shiftData: event,
          shiftDate: event.shiftDate,
          shiftId: event.shiftId,
          text: setTextForDoctorNotification(event, userDataParsed),
          time: formatRelativeDate(event.acceptedDoctor.timestamp),
          status: 'unread'
        }));
      
      setNotificationObject(formattedShiftDataForNotifications);
    }else{
      setNotificationObject({});
      // console.log("setting notification object empty");
    }

    function setTextForDoctorNotification(event, userDataParsed){
      // console.log("event inside setTextForDoctorNotification: ", event);
      let text = "";

      // console.log("event.acceptedDoctor?.doctorId: " + event.acceptedDoctor?.doctorId + " vs. userData.userId: " + userDataParsed.userData.userId);
      if(event.acceptedDoctor?.doctorId === userDataParsed.userData.userId){
        text = "Accepted to " + event.clinicData.clinicName + " - " + formatDate(event.shiftDate)
      }else{
        text = "Not Accepted to " + event.clinicData.clinicName + " - " + formatDate(event.shiftDate)
      }

      return text
    }
  }

  // NOTIFICATIONS SHOWN TO CLINICS //
  function reformatClinicShiftObjectsForNotificationPanel(shiftsWithInterestedDoctors_ObjectList, userDataParsed){

    // TODO: Only take the most current 20 timestamped events in the list.
    // Doctor side wants the 20 most recent "clinic accepted" notification events.
    // Clinic side wants the 20 most recent "doctor applied" notification events.

    // console.log("shiftsWithInterestedDoctors_ObjectList: ", shiftsWithInterestedDoctors_ObjectList);

    // create formatted object for notifications panel on clinic side from shiftsWithInterestedDoctors_ObjectList object
    if(shiftsWithInterestedDoctors_ObjectList?.length > 0){
      let formattedShiftDataForNotifications = [];
      let index = 0;
      for(var i=0; i< shiftsWithInterestedDoctors_ObjectList?.length; i++){
        for(var j=0; j<shiftsWithInterestedDoctors_ObjectList[i]?.interestedDoctors?.length; j++){

          // console.log("shiftsWithInterestedDoctors_ObjectList[i]?.interestedDoctors: ", shiftsWithInterestedDoctors_ObjectList[i]?.interestedDoctors);

          const formattedShiftDataForNotifications_specificShift = {
            id: index + 1,
            shiftData: shiftsWithInterestedDoctors_ObjectList[i],
            shiftId: shiftsWithInterestedDoctors_ObjectList[i]?.shiftId,
            text: "Dr. " + shiftsWithInterestedDoctors_ObjectList[i]?.interestedDoctors[j]?.doctorName + " has applied to " + shiftsWithInterestedDoctors_ObjectList[i]?.clinicData?.clinicName + " - " + formatDate(shiftsWithInterestedDoctors_ObjectList[i].shiftDate),
            time: formatRelativeDate(shiftsWithInterestedDoctors_ObjectList[i]?.interestedDoctors[j]?.timestamp),
            status: 'unread',
            shiftDate: shiftsWithInterestedDoctors_ObjectList[i].shiftDate // Add shiftDate to use for sorting
          }
          formattedShiftDataForNotifications.push(formattedShiftDataForNotifications_specificShift);
          index++;
        }
      }

      // TODO: We want to list the notifications in the order that doctors have applied..
      // NOT just order them by the most recent to least recent. 
      // Sort the notifications array by shiftDate (most recent first)
      // formattedShiftDataForNotifications.sort((a, b) => {
      //   const dateA = new Date(a.shiftDate);
      //   const dateB = new Date(b.shiftDate);
      //   return dateB - dateA;
      // });

      // Reassign IDs after sorting to maintain sequential order
      formattedShiftDataForNotifications = formattedShiftDataForNotifications.map((notification, index) => ({
        ...notification,
        id: index + 1
      }));

      setNotificationObject(formattedShiftDataForNotifications);

    }
  }

  // REFACTOR - PULL OUT UTILITIES
  // Format Data Times for Notification Panel
  function formatDate(dateString) {
    const [year, month, day] = dateString.split('-').map(Number);
    const date = new Date(year, month - 1, day); // Month is 0-based in JavaScript
  
    // Options for formatting the date
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
  
    // Format the date according to the provided options
    return date.toLocaleDateString('en-US', options).replace(',', ',');
  }

  // Format Data Times for Notification Panel
  function formatRelativeDate(dateString) {
    const date = new Date(dateString);
    const now = new Date();
    const oneWeekInMillis = 7 * 24 * 60 * 60 * 1000; // One week in milliseconds
  
    const timeOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    };
  
    if (now - date > oneWeekInMillis) {
      // Format: MM/DD/YYYY h:mm AM/PM
      const dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };
      return date.toLocaleDateString('en-US', dateOptions) + ' ' + date.toLocaleTimeString('en-US', timeOptions);
    } else {
      // Format: Day of the Week, h:mm AM/PM
      const dayOptions = { weekday: 'long' };
      return date.toLocaleDateString('en-US', dayOptions) + ', ' + date.toLocaleTimeString('en-US', timeOptions);
    }
  }

  async function rePullClinicData(clinicId){

    console.log("RE-PULLING DATA INTENTIONALLY");

    // console.log("rePullClinicData - userData.orgId: ", userData.orgId);
    const clinicParams = {
      orgId: userData.orgId,
    };

    const clinicDataResponse = await getClinicAndShiftData(clinicParams);
    // console.log("rePullClinicData - clinicData: ", clinicDataResponse);
    setClinicData(clinicDataResponse?.clinicData);

    // re-populate selectedShiftToEdit after Accepting a Doctor, rePull Clinic and Shift data, and repopulate currentlySelectedShiftToEdit
    // console.log("rePullClinicData - currentlySelectedShiftToEdit: ", currentlySelectedShiftToEdit);
    if(currentlySelectedShiftToEdit != undefined){
      // loop through newly pulled data to find the same shift that is the currentlySelectedShiftToEdit.
      for(var i=0; i<clinicDataResponse?.clinicData.length; i++){
        // console.log("clinicDataResponse.clinicData[i]: ", clinicDataResponse.clinicData[i]);

        // update selected shift data to show newly accepted doctor in docotr applicant list
        if(clinicDataResponse?.clinicData[i].shiftData.length > 0){
          // console.log("clicked: " + clinicDataResponse.clinicData[i]?.clinicId);
          // setCurrectlySelectedClinicCalendar(clinicDataResponse.clinicData[i]);
          // console.log("currectlySelectedClinic from new refresh loop: ", clinicDataResponse.clinicData[i]);

          for(var j=0; j<clinicDataResponse?.clinicData[i].shiftData.length; j++){
            // console.log("clinicDataResponse.clinicData[i].shiftData: ", clinicDataResponse.clinicData[i].shiftData);
            if(clinicDataResponse?.clinicData[i].shiftData[j].shiftId === currentlySelectedShiftToEdit.shiftId){
              console.log('Found currently selected shift in newly re-pulled clinic and shift data. Re-populating state with new shift data.');

              // TEST IF THIS WORKS....
              // THIS SEEMS TO BE THE PROBLEM... THIS IS CHANGING THE SELECTED SHIFT BACK TO AN INCORRECT SHIFT...
              // IT SHOULDN't BE HITTING THIS..
              // console.log("IS THIS THE PROBLEM??");
              // console.log("PREVIOUS curentlySelectedShiftToEdit: ", currentlySelectedShiftToEdit);
              // console.log("NOW RESETTING the curentlySelectedShiftToEdit TO: ", clinicDataResponse?.clinicData[i].shiftData[j]);
              setSelectedShiftToEdit(clinicDataResponse?.clinicData[i].shiftData[j]);
            }
          }
        }

        // Set currently selected calendar
        if(clinicId == clinicDataResponse?.clinicData[i]?.clinicId){
          // console.log("clicked: " + clinicDataResponse.clinicData[i]?.clinicId);
          setCurrentlySelectedClinicCalendar(clinicDataResponse?.clinicData[i]);
          // console.log("currectlySelectedClinicCalendar from new refresh loop: ", clinicDataResponse.clinicData[i]);
        }
      }
    }
  }

  function getOrgData(organizationData) {
    try {
        return API.post("od-api", "/getOrganizationData", {
          body: organizationData,
        }).then((data) => {
          // console.log("get org data response: ", data);
          if(data.statusCode === 200){ // check response to see if succesful or not.
            const parsedBody = JSON.parse(data.body);
            // console.log("get org data response: ", parsedBody);
            return parsedBody;
          }else{
            console.log("error grabbing data from org data table.");
          }
        });
    } catch (e) {
        onError(e);
        console.log("ERROR: ", e);
    }
  };

  // REFACTOR: AND SPLIT THESE UP INTO INIDIVIDUAL CALLS.
  // Need to make sure this response doesn't get too big or will hit limit pulling all in one api call
  function getClinicAndShiftData(clinicData) {
    try {
        return API.post("od-api", "/getClinicDataAndShiftData", { 
          body: clinicData,
        }).then((data) => {
          // console.log("get clinic data response: ", data);
          if(data.statusCode === 200){ // check response to see if succesful or not.
            const parsedBody = JSON.parse(data.body);
            // console.log("get clinic data response: ", parsedBody);

            return parsedBody;
          }else{
            console.log("error grabbing data from clinic data table.");
          }
        });
    } catch (e) {
        onError(e);
        console.log("ERROR: ", e);
    }
  };

  // Get ALL Clinic Data and Shift Data - 
  function getALLClinicAndShiftData(params) {
    try {
        return API.post("od-api", "/getAllClinicDataAndShiftData", { 
          body: params,
        }).then((data) => {
          // console.log("get clinic data response: ", data);
          if(data.statusCode === 200){ // check response to see if succesful or not.
            const parsedBody = JSON.parse(data.body);
            // console.log("get ALL clinic data and shift data response: ", parsedBody);

            return parsedBody;
          }else{
            console.log("error grabbing data from clinic data table.");
          }
        });
    } catch (e) {
        onError(e);
        console.log("ERROR: ", e);
    }
  };

  function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function handleLogout() {
    console.log("stop polling on sign out.");
    stopPolling();
    await Auth.signOut();
    userHasAuthenticated(false);
    nav("/login");
  }

  // value is either a specific shift to edit, or the calendar date of the calendar square selected to create a new shift.
  const OpenModal_CreateOrEditAShift = (value) => {
    // pass an existing ShiftId to edit an existing shift, or don't pass one to create a new shift.
    if (value.clinicId != undefined) {
      console.log("setSelectedCalendarDate(value): ", value);
      setSelectedShiftToEdit(value);
    } else {
      // console.log("setSelectedCalendarDate(value): ", value);
      setSelectedCalendarDate(value);
      setSelectedShiftToEdit("");
    }
    handleCancel();
    setIsCreateOrEditShiftModalOpen(true);
  };
  
  function getItem(label, key, icon, children, type) {
    return {
      key, icon, children, label, type, 
    };
  }

  const PublicMenuItems = [
    getItem('Sign Up', 'signup', <TeamOutlined />),
    getItem('Log In', 'login', <LoginOutlined />)
  ]

  // NOTIFICATION POPOVER PANEL
  const AuthenticatedMenuItems = [
    getItem(isMobile ? 'Notifcations': '', 'notifications',

      isMobile ?
        <div style={{ disply: 'flex', justifyContent: isMobile ? '' : 'center', width: '40px', height: '60px', marginRight: '0px', marginTop: '2px' }}>

          {/* Only show notificiation badge on bell icon if there are notifications */}
          {notificationObject?.length > 0 ?
            <Badge color="#F759AB" count={notificationObject?.length} offset={[16, 0]}>
              <BellOutlined style={{ color: 'white', fontSize: '16px', cursor: 'pointer' }} />
            </Badge>
            :
            <BellOutlined style={{ color: 'white', fontSize: '16px', cursor: 'pointer' }} />
          }

        </div>
      :
      <Popover
        content={
          <NotificationContent 
              setNotificationPopoverVisibleTrigger={setNotificationPopoverVisibleTrigger} 
              notificationObject={notificationObject}
              OpenModal_CreateOrEditAShift={OpenModal_CreateOrEditAShift} 
              setSelectedShiftToEdit={setSelectedShiftToEdit}
              setCurrentlySelectedClinicCalendar={setCurrentlySelectedClinicCalendar}
              rePullClinicData={rePullClinicData}
              isMobile={isMobile} 
              userData={userData} 
          />}
        title={<div style={{
          paddingTop: isMobile ? '0px' : '8px',
          paddingLeft: isMobile ? '0px' : '8px',
          paddingRight: isMobile ? '0px' : '8px',
        }}>Notifications</div>}
        trigger="click"
        open={notificationPopoverVisible}
        onOpenChange={setNotificationPopoverVisibleTrigger}
      >
        <div style={{ disply: 'flex', justifyContent: isMobile ? '' : 'center', width: '40px', height: '60px', marginRight: '0px', marginTop: '2px' }}>

          {/* Only show notificiation badge on bell icon if there are notifications */}
          {notificationObject?.length > 0 ?
            <Badge color="#F759AB" count={notificationObject?.length} offset={[16, 0]}>
              <BellOutlined style={{ color: 'white', fontSize: '16px', cursor: 'pointer' }} />
            </Badge>
            :
              <BellOutlined style={{ color: 'white', fontSize: '16px', cursor: 'pointer' }}/>
          }

        </div>
      </Popover>
  ),
    // getItem(isMobile ? 'Settings': '', 'settings', <SettingOutlined />),
    getItem(userData !== undefined ? userData.firstName : 'Profile', 'profile', <UserOutlined />), // Populate this with User Name
    getItem('Log Out', 'logout', <LogoutOutlined />)
  ]

  const clinicSubMenuItems = [
    getItem('Calendars', 'sub1', <CalendarOutlined />, 
    // Conditionally setting a notification Badge on the calendar button in this list, if there are notifications..
    loadingData ? [] : clinicCalendarList),
    {
      type: 'divider',
    },
    getItem(
      <>
        Manage Clinics 
      </>,
      'clinics', <EditOutlined />
    ),
    getItem(
      <>
        Contacts <Tag color="blue" style={{ marginLeft: '8px' }}>Coming Soon</Tag>
      </>, 
      'contacts', <TeamOutlined />
    ),
    getItem(
      <>
        Chat <Tag color="blue" style={{ marginLeft: '8px' }}>Coming Soon</Tag>
      </>, 
      'chat', <MessageOutlined />
    ),
    getItem(
      <>
        Analytics <Tag color="blue" style={{ marginLeft: '8px' }}>Coming Soon</Tag>
      </>, 
      'analytics', <LineChartOutlined />
    ),
    getItem(
      <>
        Leave Feedback
      </>,
      'feedback', <MailOutlined />
    ),
  ];

  const doctorSubMenuItems = [
    getItem(<span ><Button style={{borderRadius: '40px'}} ><SearchOutlined /> Find Shifts </Button> </span>, 'findShifts'),
    getItem(
      <>
        {/* Notification: dynamically set count between 1 and zero */}
        {/* Conditionally set this Badge, only show it if there are notifications */}
        { notificationObject?.length > 0 ?
          <Badge color="#F759AB" dot offset={[16, 16]}>
            My Calendar
          </Badge>
        :
          <>
            My Calendar
          </>
        }

      </>, 
      'myCalendar', <CalendarOutlined />
    ),
    // getItem('Favorite Clinics ? [] : clinicCalendarList), // Set this up later for "Favorite Clinics"
    // {
    //   type: 'divider',
    // },
    getItem(
      <>
        Contacts <Tag color="blue" style={{ marginLeft: '8px' }}>Coming Soon</Tag>
      </>, 
      'contacts', <TeamOutlined />
    ),
    getItem(
      <>
        Chat <Tag color="blue" style={{ marginLeft: '8px' }}>Coming Soon</Tag>
      </>, 
      'chat', <MessageOutlined />
    ),
    getItem(
      <>
        Analytics <Tag color="blue" style={{ marginLeft: '8px' }}>Coming Soon</Tag>
      </>, 
      'analytics', <LineChartOutlined />
    ),
    getItem(
      <>
        Leave Feedback
      </>,
      'feedback', <MailOutlined />
    ),
  ];

  // Function to show the drawer
  const showDrawer = () => {
    setVisibleDrawer(true);
  };

  // Function to close the drawer
  const onCloseDrawer = () => {
    setVisibleDrawer(false);
  };

  // based on which menu item is clicked, get that ID, and do something
  function handleMenuItemClick(key) {

    console.log("key clicked: " + key);
    setSelectedKey(key);

    if(key === "signup"){
      nav("/signup");
    }else if (key === "login"){
      nav("/login");
    }else if (key === "profile"){
      nav("/profile");
    }else if (key === "logout"){
      handleLogout();
    }else if (key === "chat"){
      // open chat page
    }else if (key === "contacts"){
      // open contacts page
    }else if (key === "myCalendar"){
      nav("/calendar");
    }else if (key === "findShifts"){
      nav("/");
    } else if (key === "clinics") {
      nav("/profile");
    }else if (key === "feedback") {
      // open leave feedback modal
      setIsLeaveFeedbackModalOpen(true);
    }else if (key === "notifications") {

      // console.log("clicked on notificaitons");
      // on mobile, open notifications modal on mobile.
      if(isMobile && notificationObject?.length > 0 ){
        setisMobileNotificationsModalOpen(true);
        console.log("notifications object: ", notificationObject);
      }
    }

    // pass the "currectlySelectedClinic" to props
    for(var i=0; i<clinicData?.length; i++){
      if(key == clinicData[i].clinicId){
        console.log("clicked: " + clinicData[i].clinicId);
        setCurrentlySelectedClinicCalendar(clinicData[i]);
        // save currently selected calendar to sessionStorage
        sessionStorage.setItem("currentlySelectedClinicCalendar", clinicData[i].clinicId);
        sessionStorage.setItem("currentlySelectedClinicCalendarName", clinicData[i].clinicName);
        console.log("currectlySelectedClinicCalendar clinicName: ", clinicData[i].clinicName);

        // Setting the clinic calendar menu button to selected
        // Turn off 'custom-selected' class on menu list item
        // REFACTOR.. don't use DOM manipulation
        setTimeout(() => {
          console.log("clinicData[i]: ", clinicData[i]);
          const antMenu = document.querySelector('.menu-clinics'); // Get the main menu container
          if (antMenu) {
            const clinicMenuList = antMenu.querySelector('.ant-menu-submenu'); // Find the submenu
            const clinicList = clinicMenuList?.querySelector('.ant-menu'); // Get the nested menu (if it exists)

            if (clinicList) {
              // Select all menu items
              const clinicMenuItems = clinicList.querySelectorAll('li.ant-menu-item');
              // console.log('clinicMenuItems: ', clinicMenuItems);
              // console.log("setting all menu items - clinic list - to un-selected");
              // Remove the custom-selected class from all items
              clinicMenuItems.forEach((item) => {
                item.classList.remove('custom-selected');
                item.classList.remove('ant-menu-item-selected');

                const menuTitle = item?.querySelector('.ant-menu-title-content');
                // console.log("menuTitle: ", menuTitle);
                const antBadge = menuTitle?.querySelector('.ant-badge');
                // console.log("antBadge: ", antBadge);
                antBadge?.classList.remove('custom-selected');

                const currentlySelectedClinicCalendarName = sessionStorage.getItem("currentlySelectedClinicCalendarName");
                // console.log("currentlySelectedClinicCalendarName: ", currentlySelectedClinicCalendarName);

                if (antBadge?.innerHTML.includes(currentlySelectedClinicCalendarName)){
                  antBadge?.classList.add('custom-selected');
                }else{
                  antBadge?.classList.remove('custom-selected');
                }
                
              });
            }
          }
        }, 100); // Wait for 100ms before querying

        console.log(clinicData[i]);
        nav("/calendar");
      }
    }

    if(isMobile){
      onCloseDrawer();
    }
  };

  const closeMobileNotificationModal = () => {
    setisMobileNotificationsModalOpen(false);
  }

  // Onboarding Flow
  const OpenModal_InitialOnboard = () => {
    setIsWelcomModalOpen(true);
    setCreateOrgSequence(false);
    nav("/"); // They need to fill in their information
  };

  const OpenModal_CreateAnOrg = () => {
    console.log("inside OpenModal_CreateAnOrg");
    console.log("createOrgSequence bool: " + createOrgSequence);

    setCreateOrgSequence(true);
    handleCancel();
    setIsJoinOrgModalOpen(true);
    nav("/profile");
  };

  const OpenModal_JoinAnOrg = () => {
    handleCancel();
    setCreateOrgSequence(false);
    setIsJoinOrgModalOpen(true);
    nav("/profile");
  };

  const OpenModal_CreateAClinic = () => {
    handleCancel();
    setCreateOrgSequence(false);
    setIsCreateClinicModalOpen(true);
    nav("/profile");
  };

  const completeProfile = () => {
    setIsWelcomModalOpen(false);
    nav("/profile");
    SetIsEditDoctorProfileInformationModalOpen(true);
  };

  const handleCancel = () => {
    setIsCreateClinicModalOpen(false); 
    setIsJoinOrgModalOpen(false); 
    setIsCreateOrEditShiftModalOpen(false); 
    // setIsGenericModalOpen(false); // force user to complete onboarding
    // setIsWelcomModalOpen(false); // force user to complete onboarding
    // SetIsEditDoctorProfileInformationModalOpen(false); // force user to complete onboarding
  };

  const OpenModal_Generic = (modalTitle, bodyText, buttonFunction, buttonText, showSpinner) => {
    setGenericModalTitle(modalTitle);
    setGenericModalBodyText(bodyText);
    setGenericModalButtonFunction(() => buttonFunction);
    setGenericModalButtonText(buttonText);
    setShowGenericModalShowSpinner(showSpinner);

    handleCancel();
    setIsGenericModalOpen(true);
  };

  return (
    <GoogleMapsProvider>
      <>
        {/* {contextHolder} */}
        {!isAuthenticating}
        {userData !== undefined ?
          <>
            <div className="App">
              <Layout>
                <Header style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#0E2B4B' }}>
                  {isMobile ? (
                    <>
                      <Button type="primary" onClick={showDrawer} style={{ marginLeft: '-26px' }}>
                        <div style={{ marginTop: '0px' }}>
                          <MenuOutlined />
                        </div>
                      </Button>
                      <Logo isMobile />
                      <Drawer title="Menu" placement="left" onClose={onCloseDrawer} width={296} open={visibleDrawer}>

                        {/* Adjust menu depending on if you are a clinic or doctor, also need to adjust for mobile below. */}
                        {userData.userType == "clinicTeamMember" ?
                          <Menu
                            mode="inline"
                            defaultOpenKeys={['sub1']}
                            selectedKeys={[selectedKey]}
                            // items={isAuthenticated ? AuthenticatedMenuItems.concat(clinicSubMenuItems) : PublicMenuItems}
                            items={isAuthenticated ? clinicSubMenuItems.concat(AuthenticatedMenuItems) : PublicMenuItems}
                            onClick={({ key }) => { handleMenuItemClick(key) }}
                          />
                          :
                          <>
                            <Menu
                              mode="inline"
                              defaultOpenKeys={['sub1']}
                              // items={isAuthenticated ? AuthenticatedMenuItems.concat(doctorSubMenuItems) : PublicMenuItems}
                              items={isAuthenticated ? doctorSubMenuItems.concat(AuthenticatedMenuItems) : PublicMenuItems}
                              onClick={({ key }) => { handleMenuItemClick(key) }}
                            />
                          </>
                        }
                      
                      {/* Social Media Buttons */}
                      <SocialMediaButtons isMobile={isMobile} />
                      
                      </Drawer>
                    </>
                  ) : (
                    <>
                      <Logo />
                      <Menu theme="dark" style={{ backgroundColor: '#0E2B4B' }} mode="horizontal" items={isAuthenticated ? AuthenticatedMenuItems : PublicMenuItems}
                        onClick={({ key }) => { handleMenuItemClick(key) }}
                      />
                    </>
                  )}
                </Header>

                <Layout >
                  {isMobile || !isAuthenticated ? null : (
                    <Sider width={260} style={{ background: '#fff', borderRight: '1px solid #E9EBEE' }}>

                      {/* Adjust menu depending on if you are a clinic or doctor, also need to adjust for desktop above. */}
                      {userData.userType == "clinicTeamMember" ?
                        <Menu
                          className="menu-clinics"
                          mode="inline"
                          defaultOpenKeys={['sub1']}
                          style={{ height: '100%', borderRight: 0 }}
                          items={clinicSubMenuItems}
                          onClick={({ key }) => { handleMenuItemClick(key) }}
                        />
                        :
                        <Menu
                          mode="inline"
                          defaultOpenKeys={['sub1']}
                          style={{ height: '100%', borderRight: 0, marginTop: '10px' }}
                          items={doctorSubMenuItems}
                          // selectedKeys={[selectedKey]}
                          onClick={({ key }) => { handleMenuItemClick(key) }}
                        />
                      }
                      <SocialMediaButtons isMobile={isMobile} />
                    </Sider>
                  )}
                  {/* <Layout className="mainPage" style={{ padding: isMobile ? '0px' : '24px 24px 0px 24px'}} > */}
                  <Layout className="mainPage" 
                    style={{ 
                      padding: isMobile ? '0px' : '0px', 
                      height: isMobile && userData.userType === "clinicTeamMemeber" ? "calc(100vh - 200px)" : "100%",
                      }} >
                  {/* <Layout className="mainPage" style={{ padding: isMobile ? '0px' : '0px', }} > */}
                    <Content
                      style={{
                        // padding: 24,
                        padding: 0,
                        margin: 0,
                        minHeight: 280,
                        background: '#fff',
                      }}
                    >

                      {/*  */}
                      {/* REFACTOR: Update the app to use AppContext */}
                      {/* Uses React hook uesContext, which can act sorta like a Singleton, to not need to pass data all over the app, just make it globally accesible. */}
                      {/*  */}

                      <ErrorBoundary>
                        <AppContext.Provider
                          value={{ isAuthenticated, userHasAuthenticated }}
                        >
                          <Routes
                            isMobile={isMobile}
                            currentlySelectedClinicCalendar={currentlySelectedClinicCalendar}
                            setCurrentlySelectedClinicCalendar={setCurrentlySelectedClinicCalendar}
                            loadingData={loadingData}
                            setLoadingData={setLoadingData}
                            rePullClinicData={rePullClinicData}
                            userData={userData}
                            // isGoogleMapAPILoaded={isGoogleMapAPILoaded}
                            orgData={orgData}
                            clinicData={clinicData}
                            currentPage={currentPage}
                            interestedShifts={interestedShifts}
                            allDataForDoctorFindShiftsPage={allDataForDoctorFindShiftsPage}
                            doctorFormattedInterestedShiftDataForCalendar={doctorFormattedInterestedShiftDataForCalendar}
                            onLoginEvent={onLoginEvent}
                            OpenModal_CreateAnOrg={OpenModal_CreateAnOrg}
                            OpenModal_JoinAnOrg={OpenModal_JoinAnOrg}
                            OpenModal_CreateAClinic={OpenModal_CreateAClinic}
                            OpenModal_CreateOrEditAShift={OpenModal_CreateOrEditAShift}
                          />
                        </AppContext.Provider>
                      </ErrorBoundary>

                    </Content>
                  </Layout>
                </Layout>
              </Layout>

              {/* Onboarding Modals */}
              <WelcomeModal
                // handleCancel={handleCancel}
                userData={userData}
                isWelcomeModalOpen={isWelcomeModalOpen}
                OpenModal_JoinAnOrg={OpenModal_JoinAnOrg}
                // OpenModal_CreateAnOrg={OpenModal_CreateAnOrg} 
                OpenModal_CreateAClinic={OpenModal_CreateAClinic}
                completeProfile={completeProfile}
              ></WelcomeModal>

              <JoinOrgModal
                handleCancel={handleCancel}
                userData={userData}
                onLoginEvent={onLoginEvent}
                setIsWelcomModalOpen={setIsWelcomModalOpen}
                setIsJoinOrgModalOpen={setIsJoinOrgModalOpen}
                isJoinOrgModalOpen={isJoinOrgModalOpen}
              ></JoinOrgModal>

              <CreateClinicModal
                handleCancel={handleCancel}
                userData={userData}
                isMobile={isMobile}
                onLoginEvent={onLoginEvent}
                setIsWelcomModalOpen={setIsWelcomModalOpen}
                setIsCreateClinicModalOpen={setIsCreateClinicModalOpen}
                isCreateClinicModalOpen={isCreateClinicModalOpen}
                setIsGenericModalOpen={setIsGenericModalOpen}
              ></CreateClinicModal>

              <CreateOrEditShiftModal
                handleCancel={handleCancel}
                currentPage={currentPage}
                isMobile={isMobile}
                userData={userData}
                orgData={orgData}
                clinicData={clinicData}
                onLoginEvent={onLoginEvent}
                rePullClinicData={rePullClinicData}
                selectedCalendarDate={selectedCalendarDate}
                setIsCreateOrEditShiftModalOpen={setIsCreateOrEditShiftModalOpen}
                isCreateOrEditShiftModalOpen={isCreateOrEditShiftModalOpen}
                currentlySelectedShiftToEdit={currentlySelectedShiftToEdit}
                currentlySelectedClinicCalendar={currentlySelectedClinicCalendar}
                shiftsWithInterestedDoctors={shiftsWithInterestedDoctors} // For Clinics: pass in interested doctors to set up table for clinic to accept a doctor
              // interestedShifts={interestedShifts} // For Doctors: pass in interested shifts to set up table for doctors to view list of shifts they have applied to
              ></CreateOrEditShiftModal>

              {/* Adding Additional Doctor Information on onboarding flow */}
              <EditDoctorProfileModal
                handleCancel={handleCancel}
                isMobile={isMobile}
                currentPage={currentPage}
                userData={userData}
                orgData={orgData}
                clinicData={clinicData}
                onLoginEvent={onLoginEvent}
                isEditDoctorProfileInformationModalOpen={isEditDoctorProfileInformationModalOpen}
                SetIsEditDoctorProfileInformationModalOpen={SetIsEditDoctorProfileInformationModalOpen}
                setIsWelcomModalOpen={setIsWelcomModalOpen}
              ></EditDoctorProfileModal>

              {/* Allow users to send feedback */}
              <LeaveFeedbackModal
                userData={userData}
                isMobile={isMobile}
                isLeaveFeedbackModalOpen={isLeaveFeedbackModalOpen}
                setIsLeaveFeedbackModalOpen={setIsLeaveFeedbackModalOpen}
              ></LeaveFeedbackModal>

              {/* Need to add clinic to your organization*/}
              <GenericModal
                handleCancel={handleCancel}
                modalTitle={genericModalTitle}
                isGenericModalOpen={isGenericModalOpen}
                bodyText={genericModalBodyText}
                showButton='true'
                showLoadingSpinner={showGenericModalShowSpinner}
                buttonFunction={genericModalButtonFunction}
                buttonText={genericModalButtonText}
                zIndex={1000}
              ></GenericModal>

              {/* Mobile Notifications Modal*/}
              <GenericModal
                handleCancel={closeMobileNotificationModal}
                modalTitle={"Notifications"}
                isGenericModalOpen={isMobileNotificationsModalOpen}
                bodyText={
                  <NotificationContent
                    setNotificationPopoverVisibleTrigger={setNotificationPopoverVisibleTrigger}
                    OpenModal_CreateOrEditAShift={OpenModal_CreateOrEditAShift} 
                    notificationObject={notificationObject}
                    setCurrentlySelectedClinicCalendar={setCurrentlySelectedClinicCalendar}
                    rePullClinicData={rePullClinicData}
                    isMobile={isMobile}
                    userData={userData} 
                  />}
                showButton='true'
                // showCancelButton='true'
                // showLoadingSpinner={showGenericModalShowSpinner}
                buttonFunction={closeMobileNotificationModal}
                buttonText={"Close"} // change to 'Mark All Read' eventually
                zIndex={1000}
              ></GenericModal>

              {/* Loading Modal */}
              {/* ONLY FOR CALENDAR PAGE */}
              <GenericModal
                handleCancel={handleCancel}
                modalTitle={""}
                isGenericModalOpen={loadingData}
                bodyText={""}
                showButton='false'
                show
                width='80px'
                showLoadingSpinner={true}
                buttonFunction={genericModalButtonFunction}
                // buttonText={"One Second"} 
                zIndex={1000}
              ></GenericModal>

              <Footer isMobile={isMobile} />
            </div>
          </>
          :
          <></>
        }

      </>
    </GoogleMapsProvider>
  );
}

export default App;
