import React from 'react';
import { Row, Col, Card, Typography, Button } from 'antd';
import { SearchOutlined, PlusOutlined, CalendarOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import SocialMediaButtons from "../utilityComponents/SocialMediaButtons";


const { Title, Text, Paragraph } = Typography;

export default function LandingPage(props) {

    const {
        isMobile,
    } = props

    const navigate = useNavigate();

    const openSignUpPage = () => {
        navigate('/signup');
    }

    const openSignInPage = () => {
        navigate('/login');
    }

    return (
        <div style={{ overflowX: 'hidden', overflowY: 'auto' }}>
            <div style={{ 
                display: 'flex', 
                padding: '0px 0', 
                textAlign: 'center', 
                backgroundColor: '#fff', 
                position: 'relative', 
                clipPath: 'inset(0)', 
                overflowX: 'hidden',
                overflowY: 'auto'
            }}>

                {/* Circular vector background */}
                <div
                    style={{
                        position: 'absolute',
                        top: '-5vw', // Adjust vertical offset as needed
                        left: '37vw', // Adjust horizontal offset as needed
                        width: '1200px',
                        height: '35vw',
                        background: 'radial-gradient(circle, rgba(0,123,255,0.3) 0%, transparent 70%)',
                        borderRadius: '50%',
                        zIndex: 0, // Ensure it is behind other content
                        filter: 'blur(80px)'
                    }}
                ></div>

                <Row justify="center" align="middle" gutter={[16, 16]} style={{ maxWidth: '1200px', margin: '0 auto', position: 'relative', zIndex: 9999 }}>

                    {/* Text on the left */}
                    <Col
                        xs={24}
                        md={11}
                        style={{
                            textAlign: 'left',
                            padding: isMobile ? '40px 40px 0 40px' : '0 0 0 100px',
                            position: 'relative', // Necessary for absolute positioning of the button
                        }}
                    >
                        <Title
                            style={{
                                fontSize: '46px',
                                fontStyle: 'normal',
                                fontWeight: '700',
                                lineHeight: 'normal',
                            }}
                        >
                            Connecting optometrists and clinics
                        </Title>
                        <Paragraph style={{ fontSize: '20px', color: '#667284' }}>
                            OD shift makes it easy to schedule and manage your fill-in shifts as a clinic manager or optometrist. Built by optometrists to help make your life easier.
                        </Paragraph>

                        {/* Conditional rendering for the button */}
                        {isMobile ? 
                            <>
                                <Button
                                    className="primary-button button-size-large"
                                    style={{
                                        marginTop: '12px',
                                        position: 'absolute', // Floats above other content
                                        top: '400px', // Adjust this value to position the button
                                        left: '36px', // Adjust alignment if necessary
                                        zIndex: 9999, // Ensure it is on top
                                    }}
                                    onClick={openSignUpPage}
                                >
                                    Sign up
                                </Button>
                                <Button
                                    className="secondary-button button-size-large"
                                    style={{
                                        marginTop: '12px',
                                        position: 'absolute', // Floats above other content
                                        top: '444px', // Adjust this value to position the button
                                        left: '36px', // Adjust alignment if necessary
                                        zIndex: 9999, // Ensure it is on top
                                    }}
                                    onClick={openSignInPage}
                                >
                                    Sign in
                                </Button>
                            </>
                        : 
                            <>
                                <Button
                                    className="primary-button button-size-large"
                                    style={{ marginTop: '12px' }}
                                    onClick={openSignUpPage}
                                >
                                    Sign up
                                </Button>
                                <Button
                                    className="secondary-button button-size-large"
                                    style={{ marginTop: '12px', marginLeft: '8px' }}
                                    onClick={openSignInPage}
                                >
                                    Sign in
                                </Button>
                            </>
                        }
                    </Col>

                    {/* Image on the right */}
                    <Col xs={24} md={13} style={{ 
                            textAlign: 'center', 
                            padding: '0', 
                            width: '100%',
                            marginTop: isMobile ? '-130px' : '0px', 
                            marginBottom: isMobile ? '0px' : '0px' 
                            }}>
                        <img
                            src={`${process.env.PUBLIC_URL}/Mocks.png`}
                            alt="Find shifts on a laptop"
                            style={{ 
                                width: isMobile ? '600px' : '100%', 
                                height: 'auto', 
                                marginTop: '50px' 
                            }} // Adjust marginTop if needed
                        />
                    </Col>
                </Row>
            </div>

            {/* MOCKUP SECTION */}
            <div style={{ backgroundColor: '#F7F7F7', padding: '80px', paddingRight: '16px' }}>
                <Row justify="center" align="middle" gutter={[16, 16]} style={{ maxWidth: '1200px', margin: '0 auto' }}>
                    <Col xs={24} md={16} style={{ 
                            textAlign: 'center', 
                            position: 'relative',
                            width: '100%',
                            marginLeft: isMobile ? '-60px' : '',
                            marginTop: isMobile ? '-20px' : '0px',
                            marginBottom: isMobile ? '-84px' : '0px'  
                            }}>
                        <img
                            src={`${process.env.PUBLIC_URL}/Mocks2.png`}
                            alt="Find shifts on a laptop"
                            style={{ 
                                width: isMobile ? '600px' : '100%', 
                                height: 'auto', 
                                marginBottom: '16px' 
                            }}
                        />
                    </Col>

                    <Col xs={24} md={8} 
                    style={{ 
                            padding: '16px', 
                            marginLeft: isMobile ? '-70px' : '',
                            textAlign: 'left', 
                            marginBottom: isMobile ? '-60px' : '0px' 
                            }}>
                        <Paragraph style={{ fontSize: '20px', color: '#667284' }}>
                            Take the manual effort out of finding or filling shifts, all in one simple app.
                        </Paragraph>
                    </Col>
                </Row>
            </div>

            {/* INFOGRAPHIC SECTION */}
            <div style={{ backgroundColor: '#fff', padding: '80px 20px' }}>
                {/* Wrapper with maxWidth and centering */}
                <div
                    style={{
                        maxWidth: '1200px', // Set a max width for the entire row
                        margin: '0 auto', // Center the row horizontally
                    }}
                >
                    <Row gutter={[32, 32]} justify="center" style={{ margin: 0 }}>
                        {/* Card 1 */}
                        <Col xs={24} sm={12} md={8} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Card
                                style={{
                                    maxWidth: '300px',
                                    minHeight: '320px',
                                    textAlign: 'center',
                                    borderRadius: '8px',
                                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginBottom: '16px',
                                    }}
                                >
                                    <div
                                        style={{
                                            marginTop: '16px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '48px',
                                            height: '48px',
                                            backgroundColor: '#EAF4FF',
                                            borderRadius: '8px',
                                        }}
                                    >
                                        <SearchOutlined style={{ fontSize: '24px', color: '#096dd9' }} />
                                    </div>
                                </div>
                                <h4 style={{ fontWeight: 'bold', marginTop: '26px' }}>As a doctor</h4>
                                <p style={{ color: '#595959', marginTop: '26px' }}>
                                    Search to find available shifts in your area. Filter by date, location, and pay, and connect with clinics. Get work and get paid.
                                </p>
                            </Card>
                        </Col>

                        {/* Card 2 */}
                        <Col xs={24} sm={12} md={8} style={{ display: 'flex', justifyContent: 'center'}}>
                            <Card
                                style={{
                                    maxWidth: '300px',
                                    minHeight: '320px',
                                    textAlign: 'center',
                                    borderRadius: '8px',
                                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginBottom: '16px',
                                    }}
                                >
                                    <div
                                        style={{
                                            marginTop: '16px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '48px',
                                            height: '48px',
                                            backgroundColor: '#EAF4FF',
                                            borderRadius: '8px',
                                        }}
                                    >
                                        <PlusOutlined style={{ fontSize: '24px', color: '#096dd9' }} />
                                    </div>
                                </div>
                                <h4 style={{ fontWeight: 'bold', marginTop: '26px' }}>As a clinic owner or manager</h4>
                                <p style={{ color: '#595959', marginTop: '26px' }}>
                                    Create open shifts when you need a doctor. Add shift details and view doctor information when they apply for your shift.
                                </p>
                            </Card>
                        </Col>

                        {/* Card 3 */}
                        <Col xs={24} sm={12} md={8} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Card
                                style={{
                                    maxWidth: '300px',
                                    minHeight: '320px',
                                    textAlign: 'center',
                                    borderRadius: '8px',
                                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginBottom: '16px',
                                    }}
                                >
                                    <div
                                        style={{
                                            marginTop: '16px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '48px',
                                            height: '48px',
                                            backgroundColor: '#EAF4FF',
                                            borderRadius: '8px',
                                        }}
                                    >
                                        <CalendarOutlined style={{ fontSize: '24px', color: '#096dd9' }} />
                                    </div>
                                </div>
                                <h4 style={{ fontWeight: 'bold', marginTop: '26px' }}>Stay organized</h4>
                                <p style={{ color: '#595959', marginTop: '26px' }}>
                                    Your OD Shift calendar makes it easy to view and manage your shifts. Get notifications when doctor's apply to your shifts, or when a clinic accepts you for one.
                                </p>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>


            {/* FOOTER SECTION */}
            <div style={{ backgroundColor: '#f7f7f7', padding: '60px' }}>
                <Row justify="center" align="middle" gutter={[16, 16]} style={{ maxWidth: '1200px', margin: '0 auto' }}>

                    <Col xs={24} style={{ padding: '24px', textAlign: 'center' }}>
                        <Title level={3}>
                            Ready to level up your practice or career? Join OD Shift today!
                        </Title>
                        <Button
                            className="primary-button button-size-large" style={{ marginTop: '24px' }} onClick={openSignUpPage}>
                            Sign up
                        </Button>
                        <Button
                            className="secondary-button button-size-large" style={{ marginTop: '24px', marginLeft: '8px' }} onClick={openSignInPage}>
                            Sign in
                        </Button>
                    </Col>
                    {/* Social Media Buttons */}
                    <div style={{ 
                        position: 'absolute',
                        right: '110px',
                        bottom: '20px'
                    }}>
                        <SocialMediaButtons isMobile={isMobile} />
                    </div>
                </Row>
            </div>
        </div>
    );
};