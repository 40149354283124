import React from 'react';
import { Button } from 'antd';
import { FacebookOutlined, InstagramOutlined, LinkedinOutlined, MailOutlined } from '@ant-design/icons';

const SocialMediaButtons = (props) => {

    const {
        isMobile
    } = props

  return (
    <div style={{ 
      position: 'absolute',
      bottom: isMobile ? '32px' : '26px' ,
      left: '50%',
      transform: 'translateX(-50%)',
      display: 'flex',
      gap: '14px'
    }}>
      <Button 
        type="default"
        shape="square"
        size="large"
        icon={<FacebookOutlined />}
        onClick={() => window.open('https://www.facebook.com/people/OD-Shift/61573068095066', '_blank')}
        style={{ width: '30px', height: '30px', opacity: '70%' }}
      />
      <Button
        type="default" 
        shape="square"
        size="large"
        icon={<InstagramOutlined />}
        onClick={() => window.open('https://www.instagram.com/od.shift/#', '_blank')}
        style={{ width: '30px', height: '30px', opacity: '70%' }}
      />
      <Button
        type="default"
        shape="square" 
        size="large"
        icon={<LinkedinOutlined />}
        onClick={() => window.open('https://www.linkedin.com/company/od-shift', '_blank')}
        style={{ width: '30px', height: '30px', opacity: '70%' }}
      />
      <Button
        type="default"
        shape="square"
        size="large" 
        icon={<MailOutlined />}
        onClick={() => window.open('mailto:info.odshift@gmail.com')}
        style={{ width: '30px', height: '30px', opacity: '70%' }}
      />
    </div>
  );
};

export default SocialMediaButtons; 