import React, { useState, useEffect } from "react";
import { useAppContext } from "../lib/contextLib";
import "./Home.css";
import { useNavigate } from 'react-router-dom';
import RenderShiftList from "../components/RenderShiftList.js"
import LandingPage from "../components/LandingPage.js"
import { Form, notification, Select, Col, Row, DatePicker, Button } from 'antd';
import GoogleMapOpto from "../components/GoogleMapOpto";

import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  ArrowUpOutlined 
} from '@ant-design/icons';

export default function Home(props) {
  const { isAuthenticated } = useAppContext();
  const navigate = useNavigate();

  // For Filtering
  const [filterteredListActive, setFilterteredListActive] = useState(false);
  const [filteredDataForDoctorFindShiftsPage, setFilteredDataForDoctorFindShiftsPage] = useState();
  const [numberOfShiftsAvailable, SetNumberOfShiftsAvailable] = useState(0);
  const [dates, setDates] = useState([]);

  // notification modal
  const [api, contextHolder] = notification.useNotification();
  const { Option } = Select;
  const { RangePicker } = DatePicker;

  const { 
    isMobile,
    allDataForDoctorFindShiftsPage,
    loadingData,
    userData,
    onLoginEvent,
    // isGoogleMapAPILoaded
  } = props

  // Add new state for button visibility
  const [showBackToTop, setShowBackToTop] = useState(false);

  // If the user is a clinicTeamMember, take them to the 
  useEffect(() => {
    if (isAuthenticated && userData.userType === "clinicTeamMember") {
      navigate('/profile');
    }
  }, [isAuthenticated, userData.userType, navigate]);

  // If not filtering the shift list, get the number of available or pending shifts, excluding scheduled shifts.
  useEffect(() => {
    if(!filterteredListActive){
      let shiftCountExcludingScheduledShifts = 0;
      for(var i=0; i<allDataForDoctorFindShiftsPage?.shiftData?.length; i++){
        if(allDataForDoctorFindShiftsPage?.shiftData[i]?.acceptedDoctor === undefined){

          shiftCountExcludingScheduledShifts++
        }
      }
      SetNumberOfShiftsAvailable(shiftCountExcludingScheduledShifts);
    }

  }, [allDataForDoctorFindShiftsPage]);

  // Add scroll event listener
  useEffect(() => {
    const homeDiv = document.querySelector('.Home');
    
    const handleScroll = () => {
      if (homeDiv) {
        setShowBackToTop(homeDiv.scrollTop > 100); // Show button when scrolled down 100px
      }
    };

    homeDiv?.addEventListener('scroll', handleScroll);
    
    // Cleanup listener
    return () => homeDiv?.removeEventListener('scroll', handleScroll);
  }, []); // Empty dependency array since we only want to set this up once

  // Function to handle the date change event
  const onDateChange = (dates, dateStrings) => {

    setFilterteredListActive(true);

    // 'dates' is an array of moment objects
    // 'dateStrings' is an array of formatted date strings
    console.log('Selected Dates: ', dates);
    setDates(dates);
    console.log('Formatted Selected Dates: ', dateStrings);

    // WHEN A DATE RANGE FILTER IS CLEARED //
    if(dates === null){

      console.log("handling logic after clearing date range filter.");
      setFilterteredListActive(false);

      // AFTER CLEARING DATE RANGE FILTER
      // Set SetNumberOfShiftsAvailable after clearing the date range filter
      let shiftCountExcludingScheduledShifts = 0;
      console.log("allDataForDoctorFindShiftsPage?.shiftData: ", allDataForDoctorFindShiftsPage?.shiftData);
      for(var i=0; i<allDataForDoctorFindShiftsPage?.shiftData?.length; i++){
        if(allDataForDoctorFindShiftsPage?.shiftData[i]?.acceptedDoctor === undefined){
          shiftCountExcludingScheduledShifts++
        }
      }
      console.log("shiftCountExcludingScheduledShifts: ", shiftCountExcludingScheduledShifts);
      SetNumberOfShiftsAvailable(shiftCountExcludingScheduledShifts);
    }else{

      // WHEN A DATE RANGE IS PICKED //
      // filter the list to only show shifts within that date range.
      // Check if there is no filter applied, if not, use allDataForDoctorFindShiftsPage
      // If so, pass a filtered version of the items returned in that allDataForDoctorFindShiftsPage object..

      // console.log("allDataForDoctorFindShiftsPage: ", allDataForDoctorFindShiftsPage);
      let tempFilteredDataForDoctorFindShiftsPage = [];
      for(var i=0; i< allDataForDoctorFindShiftsPage?.shiftData?.length; i++){
        // if(allDataForDoctorFindShiftsPage?.shiftData[i]?.shiftDate ){ // how to check if a dates is within a given range.
        if(isDateInRange(allDataForDoctorFindShiftsPage?.shiftData[i].shiftDate, dateStrings[0], dateStrings[1]) 
          && allDataForDoctorFindShiftsPage?.shiftData[i].shiftState !== "Scheduled"){
          // Loop through all the items and check if they are within the selected range...
          console.log("shift found within the selected date range: ", allDataForDoctorFindShiftsPage?.shiftData[i]);
          tempFilteredDataForDoctorFindShiftsPage.push(allDataForDoctorFindShiftsPage?.shiftData[i]);
        }
      }

      // console.log("------ Filtered Shifts --------");
      console.log("filteredDataForDoctorFindShiftsPage", tempFilteredDataForDoctorFindShiftsPage);
      SetNumberOfShiftsAvailable(tempFilteredDataForDoctorFindShiftsPage?.length);

      // REFACTOR.. when filtering the list, new data is not pulling into the list, because this only happens when the date range is selected above.
      setFilteredDataForDoctorFindShiftsPage(tempFilteredDataForDoctorFindShiftsPage);
    }
  };

  // Utility function to check if a date is within a range
  function isDateInRange(date, startDate, endDate) {
    // Convert date strings to Date objects
    const targetDate = new Date(date);
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Check if the targetDate is between start and end dates (inclusive)
    return targetDate >= start && targetDate <= end;
  }

  function goBackToTop() {
    const homeDiv = document.querySelector('.Home');
    if (homeDiv) {
      homeDiv.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }

  // toast notification
  const openNotification = (message) => {
    notification.open({
        message: message,
        icon: message === "Error Applying To Shift" ? 
          <CloseCircleTwoTone style={{ color: '#108ee9' }} /> 
        : 
          <CheckCircleTwoTone style={{ color: '#108ee9' }} />,
    });
  };

  const TopFilters = () => {
    return(
      <>
        <h5 style={{marginLeft: '20px', marginTop: '20px', marginBottom: '-10px'}}>Find Shifts</h5>
        <br></br>
        {/* TODO: Add button to clear the filters. */}

        {/* Filter Form */}
        <Form layout="inline" style={{marginLeft: '20px', marginBottom: isMobile ? '4px' : '-10px'}}>
          <Form.Item label="Date range" layout="vertical">
            <RangePicker
              value={dates}  // Use the 'dates' state as the value
              onChange={onDateChange}  // Update state on change
              format="YYYY-MM-DD"  // Format to display the dates
              style={{ marginTop: '-6px' }}
            />

          </Form.Item>
          {isMobile ? 
            <></>
          : 
            <>
              <Form.Item label="Job type" layout="vertical">
                <Select placeholder="Fill-in" style={{ width: 120, marginTop: '-6px' }}>
                  <Option value="fillIn">Fill-in</Option>
                  <Option disabled value="partTime">Part-time</Option>
                  <Option disabled value="permanent">Permanent</Option>
                </Select>
              </Form.Item>
              <Form.Item label="Exam type" layout="vertical">
                <Select placeholder="All" style={{ width: 120, marginTop: '-6px' }}>
                  <Option value="all">All</Option>
                  <Option disabled value="routine">Routine</Option>
                  <Option disabled value="medical">Medical</Option>
                </Select>
              </Form.Item>
            </>
          }

        </Form>
      </>
    )
  }

  // Input into JSX below...
  return (
    // TODO: Finish fix here:
    // footer is too tall on mobile, makes it a double vertical scroll bar
    <div className="Home" style={{ overflowX: 'hidden', overflow: isMobile ? 'auto' : '', display: isMobile ? 'flex' : '', flexDirection: isMobile ? 'column' : '', paddingTop: isMobile ? '' : '', alignItems: isMobile ? '' : '', height: isMobile ? 'calc(100vh - 104px)' : '' }}>
      {isAuthenticated ? 
        <>
          <TopFilters/>

          {isMobile ?
            <Row gutter={0}>
              <Col span={24} style={{marginTop: '6px'}}>
                <GoogleMapOpto 
                  isMobile={isMobile} 
                  userData={userData}
                  loadingData={loadingData} 
                  shiftList={allDataForDoctorFindShiftsPage?.shiftData} 
                  // isGoogleMapAPILoaded={isGoogleMapAPILoaded}
                />
                <RenderShiftList 
                  userData={userData} 
                  isMobile={isMobile}
                  loadingData={loadingData}
                  allDataForDoctorFindShiftsPage={allDataForDoctorFindShiftsPage} 
                  onLoginEvent={onLoginEvent}
                  filteredDataForDoctorFindShiftsPage={filteredDataForDoctorFindShiftsPage}
                  numberOfShiftsAvailable={numberOfShiftsAvailable}
                  filterteredListActive={filterteredListActive}
                  />
              </Col>

              {/* Back to Top - Bottom Center Button */}
              {isMobile && showBackToTop && (
                <Button 
                  className="primary-button button-size-default" 
                  style={{ 
                    position: 'fixed', 
                    // bottom: '76px', 
                    bottom: '9vh',
                    left: '50%', 
                    transform: 'translateX(-50%) scale(1.22)',
                    transition: 'opacity 0.3s',
                    opacity: showBackToTop ? 1 : 0
                  }}
                  onClick={goBackToTop}
                >
                  Back to Top
                  <ArrowUpOutlined />
                </Button>
              )}
            </Row>
          : 
            <Row gutter={0}>
              <Col span={8} style={{paddingLeft: '0px' , marginTop: '6px'}}>
                <RenderShiftList 
                  userData={userData} 
                  isMobile={isMobile}
                  loadingData={loadingData}
                  allDataForDoctorFindShiftsPage={allDataForDoctorFindShiftsPage}
                  onLoginEvent={onLoginEvent}
                  filteredDataForDoctorFindShiftsPage={filteredDataForDoctorFindShiftsPage}
                  numberOfShiftsAvailable={numberOfShiftsAvailable}
                  filterteredListActive={filterteredListActive}
                  />
              </Col>
              <Col span={16} style={{marginTop: '20px'}}>
                <p style={{fontSize: '13px' , marginTop: '-24px', position: 'absolute', right: '26px' }}>*available shifts within 50 mile radius from you ({userData.zipCode})</p>
                <GoogleMapOpto 
                  isMobile={isMobile} 
                  userData={userData}
                  loadingData={loadingData} 
                  shiftList={allDataForDoctorFindShiftsPage?.shiftData} 
                  // isGoogleMapAPILoaded={isGoogleMapAPILoaded}
                />
              </Col>
            </Row>
          }

        </>
      : 
        // Render the public landing page
        <LandingPage isMobile={isMobile} />
      }
    </div>
  );
}