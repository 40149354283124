import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Layout, Menu, Drawer, Grid, Tag, Badge, Spin, List, Row, Col, Popover, Typography } from 'antd';
import { OpenClinicCalendar } from '../utilityComponents/profileComponents/profileComponents';
const { Text } = Typography;

export default function NotificationContent(props) {

    const {
        isMobile,
        userData,
        notificationObject,
        setNotificationPopoverVisibleTrigger,
        OpenModal_CreateOrEditAShift,
        setSelectedShiftToEdit,
        setCurrentlySelectedClinicCalendar,
        rePullClinicData
    } = props

    const nav = useNavigate();
    console.log("notificationObject: ", notificationObject);

    // Jump directly to the list of applied doctors for this specific shift.
    // pass in the shiftData object of the shift that is associated to the notification.
    function viewDoctor(item){

        // Set setDoctorListLoading(true); // Check on this.... calendar items not populated right off the bat... why?
        OpenClinicCalendar({ setCurrentlySelectedClinicCalendar, nav, clinic: item.shiftData.clinicData, rePullClinicData });

        // Close the notifications panel
        setNotificationPopoverVisibleTrigger();
        setSelectedShiftToEdit(item.shiftData);
        // open modal to list of applied doctors.
        console.log("clicked on 'view doctor' button for item: ", item);
        // console.log("clicked on 'view doctor' button for shiftData: ", item.shiftData);
        // console.log("clicked on 'view doctor' button for shift Id: ", item.shiftId);
        // look to see how we open the modal when in the calendar... mimic the same logic to open the modal but from the notification drowpdown
        OpenModal_CreateOrEditAShift(item.shiftData);
    }

    return (
        <>
            {isMobile ?
                <div style={{ width: '100%' }}>
                    <div style={{ maxHeight: '200px', overflowY: 'auto', paddingRight: '8px', paddingLeft: '8px' }}>
                        <List
                            dataSource={notificationObject || []}
                            renderItem={item => (
                                <>
                                    {item ?
                                        <List.Item>
                                            <Row style={{ width: '100%' }}>
                                                <Col span={1}>
                                                    {item.status === 'unread' && (
                                                        <div className="custom-badge-wrapper">
                                                            <Badge dot />
                                                        </div>
                                                    )}
                                                </Col>
                                                <Col span={15}>
                                                    <Text>{item.text}</Text>
                                                </Col>
                                                <Col span={8} style={{ textAlign: 'right', color: '#8c8c8c', paddingRight: '16px' }}>
                                                    {item.time}
                                                </Col>
                                                {
                                                    userData?.userType === "individualDoctor" ?
                                                        <></>
                                                        :
                                                        <>
                                                            {/* View Doctor */}
                                                            <Col span={3}>
                                                                <Button style={{ borderRadius: '40px', paddingRight: '14px' }} onClick={() => viewDoctor(item)}>Reviewr</Button>
                                                            </Col>
                                                        </>
                                                }
                                            </Row>
                                        </List.Item>
                                        :
                                        <>
                                        </>
                                    }

                                </>
                            )}
                        />
                    </div>
                    {isMobile ?
                        <></>
                        :
                        <div style={{ textAlign: 'right', padding: '8px', marginTop: '8px' }}>
                            <Button className="secondary-button button-size-default" onClick={setNotificationPopoverVisibleTrigger} style={{ marginRight: 8 }}>
                                Close
                            </Button>
                            {/* <Button className="primary-button button-size-default">
                                Mark All Read
                            </Button> */}
                        </div>
                    }
                </div>
            :
                <div style={{ width: userData?.userType === "individualDoctor" ? '600px' : '740px' }}>
                    <div style={{ maxHeight: '200px', overflowY: 'auto', paddingRight: '8px', paddingLeft: '8px' }}>
                        <List
                            dataSource={notificationObject || []} // Fallback to an empty array
                            renderItem={item => (
                                <>
                                    {item ?
                                        <List.Item>
                                            <Row style={{ width: '100%' }}>
                                                <Col span={1}>
                                                    {item.status === 'unread' && (
                                                        <div className="custom-badge-wrapper">
                                                            <Badge dot />
                                                        </div>
                                                    )}
                                                </Col>
                                                {/* TODO: Change these spans conditionally if the user is doctor or clinic */}
                                                <Col span={15}> 
                                                    <Text>{item.text}</Text>
                                                </Col>
                                                {
                                                    userData?.userType === "individualDoctor" ? 
                                                    <></> 
                                                    : 
                                                    <>
                                                        {/* View Doctor */}
                                                        <Col span={2}> 
                                                                <Button style={{ borderRadius: '40px' }} onClick={() => viewDoctor(item)}>Review</Button>
                                                        </Col>
                                                    </>
                                                }
                                                <Col span={6} style={{ textAlign: 'right', color: '#8c8c8c', paddingRight: '16px' }}>
                                                    {item.time}
                                                </Col>
                                            </Row>
                                        </List.Item>
                                        :
                                        <>
                                        </>
                                    }

                                </>
                            )}
                        />
                    </div>
                    {isMobile ?
                        <></>
                        :
                        <div style={{ textAlign: 'right', padding: '8px', marginTop: '8px' }}>
                            <Button className="secondary-button button-size-default" onClick={setNotificationPopoverVisibleTrigger} style={{ marginRight: 8 }}>
                                Close
                            </Button>
                            {/* <Button className="primary-button button-size-default">
                                Mark All Read
                            </Button> */}
                        </div>
                    }
                </div>
            }
        </>
    );
};
