import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { onError } from "../lib/errorLib";
// import config from "../config";
import "./Settings.css";

export default function Settings(props) {
  const nav = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

  const {
    // userData,
    orgData
    // onLoginEvent,
  } = props

  useEffect(() => {
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
  }, []);

  // Stripe testing CC number (all other information can be random)
  // 4242 4242 4242 4242

  async function handleFormSubmit() {
    setIsLoading(true);

    console.log("orgData.orgId to send to stripe: ", orgData.orgId);

    // DYNAMICALLY PASS IN THE COMPANY ID BELOW
    // pull this in through props...
    try {
      const response = await API.post("od-api", "/billing", {
        body: { 
          priceId: process.env.REACT_APP_STRIPE_PRICE_ID,
          companyId: orgData.orgId 
        },
      });
      console.log("response: ", response);
      const { id, subscriptionStatus } = JSON.parse(response.body);

      // // Handle existing subscription
      if (subscriptionStatus) {
        switch (subscriptionStatus) {
          case 'active':
          case 'trialing':
            setModalMessage("You already have an active subscription.");
            setShowModal(true);
            return;
          case 'past_due':
            setModalMessage("Your subscription needs attention. Please update your payment method.");
            setShowModal(true);
            return;
          case 'canceled':
            // Proceed with new subscription
            break;
          default:
            // Proceed with new subscription
            break;
        }
      }

      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({ 
        sessionId: id,
      });

      if (error) {
        setModalMessage("There was an error processing your payment.");
        setShowModal(true);
      }
    } catch (e) {
      onError(e);
      setModalMessage("There was an error processing your payment.");
      setShowModal(true);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="Settings">
      <button onClick={handleFormSubmit} disabled={isLoading}>
        {isLoading ? "Loading..." : "Subscribe"}
      </button>

      {/* TODO: Create a proper modal here */}
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => setShowModal(false)}>&times;</span>
            <p>{modalMessage}</p>
          </div>
        </div>
      )}
    </div>
  );
}
