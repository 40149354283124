import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./containers/Login";
import Signup from "./containers/Signup";
import Home from "./containers/Home";
import PrivacyPolicy from "./containers/PrivacyPolicy";
import Settings from "./containers/Settings";
import Calendar from "./containers/Calendar";
import NotFound from "./containers/Calendar";
import Profile from "./containers/Profile";
import Mal from "./containers/Mal";
import ResetPassword from "./containers/ResetPassword";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

export default function Links(props) {

  const { 
    isMobile, 
    currentlySelectedClinicCalendar,
    setCurrentlySelectedClinicCalendar,
    loadingData,
    setLoadingData,
    userData, 
    orgData,
    allDataForDoctorFindShiftsPage,
    clinicData,
    onLoginEvent,
    OpenModal_CreateAnOrg,
    OpenModal_JoinAnOrg,
    OpenModal_CreateAClinic,
    OpenModal_CreateOrEditAShift,
    rePullClinicData,
    interestedShifts,
    currentPage,
    doctorFormattedInterestedShiftDataForCalendar,
    // isGoogleMapAPILoaded
  } = props

  return (
    <Routes>
      <Route 
        path="/" 
        element={<Home 
          isMobile={isMobile}
          loadingData={loadingData}
          userData={userData}
          interestedShifts={interestedShifts}
          // isGoogleMapAPILoaded={isGoogleMapAPILoaded}
          allDataForDoctorFindShiftsPage={allDataForDoctorFindShiftsPage}
          onLoginEvent={onLoginEvent}
        />} />
      <Route
        path="/privacyPolicy"
        element={
            <PrivacyPolicy
              isMobile={isMobile}
            />
        } />
      <Route
        path="/login"
        element={
          <UnauthenticatedRoute>
            <Login 
              isMobile={isMobile} 
              onLoginEvent={onLoginEvent} 
              />
          </UnauthenticatedRoute>
        }
      />
      <Route
        path="/signup"
        element={
          <UnauthenticatedRoute>
            <Signup 
             isMobile={isMobile} 
             onLoginEvent={onLoginEvent} 
             setLoadingData={setLoadingData}
             />
          </UnauthenticatedRoute>
        }
      />
      <Route
        path="/login/reset"
        element={
          <UnauthenticatedRoute>
            <ResetPassword isMobile={isMobile}/>
          </UnauthenticatedRoute>
        }
      />
      <Route
        path="/calendar"
        element={
          <AuthenticatedRoute>
            <Calendar 
              isMobile={isMobile}
              currentlySelectedClinicCalendar={currentlySelectedClinicCalendar}
              loadingData={loadingData}
              userData={userData}
              clinicData={clinicData}
              interestedShifts={interestedShifts}
              rePullClinicData={rePullClinicData}
              doctorFormattedInterestedShiftDataForCalendar={doctorFormattedInterestedShiftDataForCalendar}
              orgData={orgData}
              OpenModal_CreateAClinic={OpenModal_CreateAClinic} 
              OpenModal_CreateOrEditAShift={OpenModal_CreateOrEditAShift} 
            />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <AuthenticatedRoute>
            <Profile 
              isMobile={isMobile}
              currentPage={currentPage}
              loadingData={loadingData}
              userData={userData} 
              orgData={orgData}
              clinicData={clinicData}
              setCurrentlySelectedClinicCalendar={setCurrentlySelectedClinicCalendar}
              rePullClinicData={rePullClinicData}
              onLoginEvent={onLoginEvent}
              OpenModal_CreateAnOrg={OpenModal_CreateAnOrg} 
              OpenModal_JoinAnOrg={OpenModal_JoinAnOrg} 
              OpenModal_CreateAClinic={OpenModal_CreateAClinic}
              OpenModal_CreateOrEditAShift={OpenModal_CreateOrEditAShift}
            />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/mal"
        element={
          <AuthenticatedRoute>
            <Mal 
              isMobile={isMobile} 
              loadingData={loadingData}
              userData={userData} 
              orgData={orgData}
              clinicData={clinicData}
              OpenModal_CreateAnOrg={OpenModal_CreateAnOrg} 
              OpenModal_JoinAnOrg={OpenModal_JoinAnOrg} 
              OpenModal_CreateAClinic={OpenModal_CreateAClinic}
              OpenModal_CreateOrEditAShift={OpenModal_CreateOrEditAShift}
              />
          </AuthenticatedRoute>
        }
      />
      {/* <Route
        path="/admin"
        element={
          <AuthenticatedRoute>
            <Admin
              isMobile={isMobile}
              loadingData={loadingData}
              userData={userData}
              orgData={orgData}
              clinicData={clinicData}
              OpenModal_CreateAnOrg={OpenModal_CreateAnOrg}
              OpenModal_JoinAnOrg={OpenModal_JoinAnOrg}
              OpenModal_CreateAClinic={OpenModal_CreateAClinic}
              OpenModal_CreateOrEditAShift={OpenModal_CreateOrEditAShift}
            />
          </AuthenticatedRoute>
        }
      /> */}
      <Route
        path="/payments"
        element={
          <AuthenticatedRoute>
            <Settings 
              isMobile={isMobile} 
              userData={userData}
              orgData={orgData}
            />
          </AuthenticatedRoute>
        }
      />
      {/* Finally, catch all unmatched routes */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
