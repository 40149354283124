import React from "react";
import "./Profile.css";
import {
  AvatarNameCard,
  PersonalInfoTitle, 
  PersonalInfo, 
  DoctorCard_ProfessionalTitle, 
  DoctorCard_ProfessionalInfo, 
  ClinicCard_ClinicTitle,
  DoctorCard_PreferencesTitle,
  DoctorCard_PreferencesInfo
} from "../utilityComponents/profileComponents/profileComponents.js";
import { ClinicInformation_Profile } from "../utilityComponents/profileComponents/ClinicInformation_Profile.js"
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from 'antd';

export default function Profile(props) {

  const nav = useNavigate();

  const { 
    isMobile, 
    currentPage,
    userData, 
    clinicData,
    orgData, 
    rePullClinicData,
    OpenModal_CreateAClinic, 
    onLoginEvent,
    setCurrentlySelectedClinicCalendar
  } = props

  const goToPaymentsPage = () => {
    nav("/payments");
  }

  return (
    <>
      {/* User Profiles */}
      <div className="page-background" style={{overflow: 'auto', display: 'flex', flexDirection: 'column', paddingTop: '40px', alignItems: 'center', height: 'calc(100vh - 104px)' }}>
        <AvatarNameCard userData={userData} orgData={orgData} isMobile={isMobile} />
        <PersonalInfoTitle isMobile={isMobile}/>
        <PersonalInfo currentPage={currentPage} userData={userData} onLoginEvent={onLoginEvent} isMobile={isMobile} />
        <>
          {userData.userType == 'clinicTeamMember' ? 
            <>
              {/* <Button onClick={goToPaymentsPage}>Manage Payments</Button> */}
              <ClinicCard_ClinicTitle OpenModal_CreateAClinic={OpenModal_CreateAClinic} isMobile={isMobile} />

              {/* Map - dynamically create each clinic card  */}
              {clinicData && clinicData.length > 0 ? (
                <>
                  {clinicData.map((clinic, index) => (
                    <ClinicInformation_Profile 
                      key={index} 
                      onLoginEvent={onLoginEvent} 
                      isMobile={isMobile} 
                      clinic={clinic}
                      rePullClinicData={rePullClinicData}
                      setCurrentlySelectedClinicCalendar={setCurrentlySelectedClinicCalendar}
                    />
                  ))}
                </>
              ) : (
                <p style={{marginTop: '26px'}}>No clinics available</p>
              )}
              <br></br>
            </>
          : 
            <>
              <DoctorCard_ProfessionalTitle isMobile={isMobile} />
              <DoctorCard_ProfessionalInfo userData={userData} isMobile={isMobile} onLoginEvent={onLoginEvent}/>
              <DoctorCard_PreferencesTitle isMobile={isMobile}/>
              <DoctorCard_PreferencesInfo userData={userData} isMobile={isMobile} onLoginEvent={onLoginEvent} />
            </>
          }
        </>
        <p
          style={{
            marginTop: isMobile ? "-10px" : 'auto', // Push to the bottom if needed
            marginBottom: isMobile ? "30px" : '',
            alignSelf: 'flex-start', // Align this paragraph to the right of the parent div
            marginLeft: '13%', // Optional: Add spacing from the right edge
          }}
        >
          View the OD Shift <a href="https://odshift.com/privacyPolicy" target="_blank">Privacy Policy</a>
        </p>
      </div>

    </>

  );
}